export const sections = [

    {
        section: "Littéraire",
        options: [
            {
                option: "Latin-Grec",
                cours: [
                    "Anglais", "Biologie", "Civisme", "Français", "Géographie",
                    "Grec", "Histoire", "Latin", "Mathématique", "Philosophie", "Physique"
                ]
            },
            {
                option: "Latin-Mathématiques",
                cours: [
                     "Civisme", "Géographie", "Histoire", "Philosophie", "Latin",
                     "Biologie", "Mathématique", "Physique", "Anglais", "Français"
                ]
            },
            {
                option: "Latin-Philosophie",
                cours: [
                    "Anglais", "Biologie", "Civisme", "Français", "Géographie",
                    "Histoire", "Latin", "Mathématique", "Philosophie", "Physique"
                ]
            }
        ]
    },

    {
        section: "Scientifique",
        options: [
            {
                option: "Sciences",
                cours: [
                   "Civisme", "Géographie", "Histoire", "Philosophie", "Mathématiques",
                   "Biologie", "Ecologie", "Géologie", "Evolution", "Chimie", "Physique", "Anglais", 
                   "Français"
                ]
            }
        ]
    },

    {
        section: "Pédagogie",
        options: [
            {
                option: "Pédagogie générale",
                cours: [
                     "Civisme", "Géographie", "Histoire", "Philosophie", "Didactique générale", 
                     "Didactique des disciplines", "Psychologie générale", "Psychologie de l’enfant", 
                     "Pédagogie", "Biologie", "Mathématique", "Physique", "Anglais", "Français"
                ]
            },
            {
                option: "Education Physique et Sportive",
                cours: [
                     "Civisme", "Géographie", "Histoire", "Didactique des disciplines", 
                     "Psychologie de l’enfant et de l’adolescent", "Théorie de l’éducation physique et sportive",
                     "Pédagogie", "Biologie", "Anatomie", "Physiologie", "Chimie", "Mathématique",
                     "Physique", "Anglais", "Français"
                ]
            },
            {
                option: "Normale",
                cours: [
                      "Civisme", "Géographie", "Histoire", "Philosophie", "Didactique générale",
                      "Didactique des disciplines", "Psychologie générale", "Psychologie de l’enfant", 
                      "Pédagogie", "Ecologie", "Mathématique", "Physique", "Anglais", "Français"

                ]
            },
            {
                option: "Pédagogie maternelle",
                cours: [ 
		             "Civisme", "Histoire", "Philosophie", "Hygiène", "Didactique générale", "Didactique des disciplines", 
                     "Psychologie générale", "Psychologie de l’enfant", "Pédagogie", "Exploration du milieu", 
                     "Mathématique", "Physique", "Informatique", "Anglais", "Français"
                ]
            },
        ]
    },

    {
        section: "Technique",
        options: [
            {
                option: "Sociale",
                cours: [
                    "Anglais", "Chimie", "Comptabilité", "Développement communautaire", "Démographie",
                    "Économie politique", "Éducation à la citoyenneté", "Éducation à la vie", "Français",
                    "Géographie", "Histoire", "Histoire du travail", "Hygiène alimentaire", "Hygiène familiale", "Informatique",
                    "Institution sociale", "Mathématiques", "Microbiologie", "Organisation scientifique du travail",
                    "Physique", "Psychologie générale", "Religion", "Secourisme", "Sociologie Africaine",
                    "Technique d’animation"
                ]
            }
        ]
    },

    {
        section: "Techniques commerciales",
        options: [
            {
                option: "Commerciale et Gestion",
                cours: [
                    "Déontologie professionnelle", "Education à la citoyenneté", "Comptabilité analytique de gestion", 
                    "Informatique", "Fiscalité", "Finances publiques", "Législation sociale", "Economie du développement", "Organisation des entreprises",
                    "Mathématique", "Anglais", "Correspondance commerciale anglaise", "Correspondance commerciale française", "Français"
                ]
            },
            {
                option: "Secrétariat - Administration",
                cours: [
                    "Anglais", "Comptabilité générale", "Correspondence commerciale et administrative anglaise",
                    "Correspondence commerciale et administrative française", "Deontologie du secrétariat",
                    "Droit", "Éducation à la citoyenneté", "Éducation physique", "Entreprenariat",
                    "Fiscalité", "Français", "Informatique", "Langues nationales", "Mathématique",
                    "Organisation de bureau", "Organisation des entreprises", "Sténographie", "Statistiques"
                ]
            }
        ]
    },

    {
        section: "Techniques industrielles",
        options: [
            {
                option: "Électricité",
                cours: [
                    "Civisme", "Actualité", "Applications électriques", "Machines électriques", "Mécanique appliquée",
                    "Mathématique", "Automation", "Electronique", "Organisation des entreprises", "Anglais", "Français"
                ]
            },
            {
                option: "Électronique industrielle",
                cours: [
                    "Anglais", "Circuits logiques", "Dessin électronique", "Dessin industriel", "Éducation civique et politique",
                    "Électricité générale", "Électricité industrielle", "Électronique générale", "Électronique industrielle",
                    "Français", "Géographie", "Histoire", "H.G.A", "Informatique", "Instruments et appareillage et méthode de mesure",
                    "Laboratoire", "Mathématiques", "Mécanique", "Métallurgie", "Organisation", "Physique", "Radio électricité",
                    "Radio TV", "Regulation", "Religion", "Sociologie", "Technologie électrique", "Technologie électronique"
                ]
            },
            {
                option: "Mécanique-automobile",
                cours: [
                    "Anglais", "Automation", "Chimie", "Construction auto", "Dessin industriel",
                    "Éducation à la citoyenneté", "Éducation à la vie", "Électricité auto", "Électricité générale",
                    "Électronique", "Français", "Géographie", "Histoire", "Informatique", "Laboratoire électronique",
                    "Mathématiques", "Mécanique appliquée", "Mécanique générale", "Métallurgie", "Moteur thermique",
                    "Physique", "R.D.M", "Religion", "Techno-Auto", "Techno-Méca"
                ]
            },
            {
                option: "Mécanique Machines-Outils",
                cours: [
                    "Civisme", "Actualité", "Mécanique appliquée", "Résistance des matériaux et éléments des machines (R.D.M. et E.D.M)",
                    "Construction mécanique", "Mathématique", "Electricité générale", "Transmission hydraulique et pneumatique",
                    "Organisation des entreprises", "Anglais", "Français"
                ]
            },
            {
                option: "Mécanique générale",
                cours: [
                    "Civisme", "Actualité", "Mécanique appliquée", "Résistance des matériaux et éléments des machines (R.D.M. et E.D.M)",
                    "Construction mécanique", "Mathématique", "Electricité générale", "Transmission hydraulique et pneumatique",
                    "Organisation des entreprises", "Anglais", "Français"
                ]
            },
            {
                option: "Pétrochimie",
                cours: [
                    "Actualité", "Anglais", "Chimie analytique", "Chimie générale", "Chimie industrielle",
                    "Chimie organique", "Dessin technique", "Écologie", "Électricité générale", "Français",
                    "Géographie", "Géologie", "Histoire", "Hydraulique", "Laboratoire de chimie", "Mathématiques",
                    "Petrochimie", "Physique", "Physique industrielle", "Pratique électrique", "Raffinage",
                    "Regulation", "Religion", "Technologie du pétrole et du gaz", "Forage et production pétrolière"
                ]
            },
            {
                option: "Construction",
                cours: [
                    "Civisme", "Actualité", "Béton armé", "Procédés généraux de construction",
                    "Résistances des matériaux (R.D.M.)", "Voies de communication", "Législation", "Mathématique",
                    "Electricité", "Outillage de chantier", "Organisation des entreprises", "Anglais", "Français"
                ]
            },
            {
                option: "Chimie Industrielle",
                cours: [
                    "Civisme", "Actualité", "Chimie industrielle", "Physique industrielle", "Régulation", "Mathématique",
                    "Métallurgie", "Organisation des entreprises", "Anglais", "Français"
                ]
            },
            {
                option: "Imprimerie",
                cours: [
                    "Civisme", "Actualité", "Techno générale", "Composition", "Esthétique", "Machine typo", "Reprographie",
                    "Machine offset", "Mathématique", "Calculation", "Organisation des entreprises", "Anglais", "Français"
                ]
            },
            {
                option: "Commutation",
                cours: [
                    "Civisme", "Actualité", "Géographie", "Histoire", "Circuits logiques", "Communication télégraphique",
                    "Commutation téléphonique", "Electricité", "Electronique", "Mathématique", "Téléphonie générale", 
                    "Transmission télégraphique et numérique", "Organisation des entreprises", "Anglais", "Français"
                ]
            },
            {
                option: "Radio Transmission",
                cours: [
                    "Civisme", "Actualité", "Géographie", "Histoire", "Antennes et propagation", "Circuits logiques", 
                    "Courant porteur", "Electricité", "Electronique", "Emission et Réception", "Mathématique", "Hyperfréquences",
                    "Radiotélévision", "Organisation des entreprises", "Anglais", "Français"
                ]
            },
            {
                option: "Météorologie",
                cours: [
                    "Civisme", "Actualité", "Géographie", "Histoire", "Climatologie générale", "Circuits logiques", "Electricité",
                    "Télécommunication météo", "Radioélectricité", "Mathématique", "Météo générale", "Météo spéciale", 
                    "Météo tropicale", "Notions d’informatique", "Organisation des entreprises", "Anglais", "Français"
                ]
            },
            {
                option: "Aviation Civile",
                cours: [
                    "Civisme", "Actualité", "Géographie", "Histoire", "Service d’information aéronautique", "Aérotechnique",
                    "Navigation aérienne", "Opération aérienne", "Règlement du transport aérien", 
                    "Réglementation de la circulation aérienne", "Sécurité et lutte contre l’incendie", "Mathématique", 
                    "Circuits logiques", "Notions d’informatique", "Notions de droit aérien", "Organisation des entreprises", 
                    "Anglais", "Français"
                ]
            },
            {
                option: "Mécanique Dessin",
                cours: [
                     "Civisme", "Actualité", "Mécanique appliquée", "Résistance des matériaux et éléments des machines (R.D.M. et E.D.M.)",
                     "Organes de transmissions", "Construction", "Mathématique", "Electricité industrielle",
                     "Organisation des entreprises", "Anglais", "Français"
                ]
            },
            {
                option: "Hydropneumatique",
                cours: [
                     "Civisme", "Actualité", "Mécanique appliquée", "Résistance des matériaux et éléments des machines (R.D.M. et E.D.M.)",
                     "Technologie hydropneumatique", "Construction", "Mathématique", "Electricité industrielle",
                     "Organisation des entreprises", "Anglais", "Français"
                ]
            },
            {
                option: "Construction Metallique",
                cours: [
                     "Civisme", "Actualité", "Technologie de soudure", "Construction métallique", "Résistance des matériaux (R.D.M.)",
                     "Traçage de tôlerie et chaudronnerie", "Mathématique", "Electricité", " Mecanique Appliquée",
                     "Organisation des entreprises", "Anglais", "Français"
                ]
            },
            {
                option: "Menuiserie",
                cours: [
                     "Civisme", "Actualité", "Technologie machines", "Technologie meubles", "Mécanique appliquée",
                     "Résistance des matériaux (R.D.M.)", "Législation fiscale et sociale", "Mathématique", 
                     "Menuiserie de bâtiment", "Comptabilité", "Organisation des entreprises", "Anglais", "Français" 
                ]
            },
            {
                option: "Mine et Geologie",
                cours: [
                     "Civisme", "Actualité", "Minéralogie", "Géologie générale", "Chimie industrielle", "Sécurité industrielle",
                     "Résistance des matériaux", "Régulation", "Mathématique", "Métallurgie", "Technique graphique", 
                     "Organisation des entreprises", "Anglais", "Français"
                ]
            },
            {
                option: "Metallurgie",
                cours: [
                     "Civisme", "Actualité", "Métallurgie", "Eléments de Génie Métallurgique", "Régulation", "Physique industrielle",
                     "Mathématique", "Métallographie", "Chimie industrielle", "Organisation des entreprises", "Anglais", "Français"
                ]
            },
            {
                option: "Dessin de Batiment",
                cours: [
                     "Civisme", "Actualité", "Béton armé", "Procédés généraux de construction", "Résistance des matériaux", 
                     "Histoire d’architecture", "Législation", "Direction des travaux", "Mathématique", 
                     "Organisation des entreprises", "Anglais", "Français"
                ]
            },
            {
                option: "Plomberie (Installation Sanitaire)",
                cours: [
                     "Civisme", "Actualité", "Technologie", "Environnement", "Mécanique", "Législation", "Electricité",
                     "Calcul professionnel", "Mathématique", "Organisation des entreprises", "Anglais", "Français"
                ]
            },
            {
                option: "Mines et Carrières",
                cours: [
                     "Civisme", "Actualité", "Exploitation des mines souterraines", "Exploitation des mines à ciel ouvert", 
                     "Géologie", "Mécanique appliquée", "Mathématique", "Mécanique des roches", "Organisation des entreprises",
                     "Technique hydro-pneumatique", "Topographie", "Automation des mines", "Anglais", "Français"
                ]
            },
            {
                option: "Geologie",
                cours: [
                     "Civisme", "Actualité",  "Analyse des données géologiques", "Levés géologiques", "Levés géophysique", 
                     "Levés géochimiques", "Santé et sécurité du Travail", "Mathématique", 
                     "Instruments et équipements de diagraphie de SLE", "Entrepreneuriat", "Anglais", "Français"
                ]
            },
        ]
    },

    {
        section: "Technique artistique",
        options: [
            {
                option: "Art Plastique",
                cours: [
                    "Civisme", "Géographie", "Histoire", "Philosophie", "Anatomie artistique", "Esthétique", 
                    "Filmologie", "Histoire de l’art", "Musicologie", "Mathématique", "Physique", "Anglais", 
                    "Français"
                ]
            },
            {
                option: "Arts Dramatiques",
                cours: [
                     "Civisme", "Géographie", "Histoire", "Philosophie", "Histoire du décor et du costume",
                     "Histoire du théâtre", "Esthétique théâtrale", "Biologie", "Mathématique", "Anglais", "Français"
                ]
            },
            {
                option: "Musique",
                cours: [
                     "Civisme", "Géographie", "Histoire", "Philosophie", "Biologie", "Mathématique", "Physique",
                     "Anglais", "Français", "Auditions musicales", "Histoire de la musique", "Théorie musicale",
                     "Transposition", 
              ]
            },
            {
                option: "Esthétique et Coiffure",
                cours: [
                     "Civisme", "Géographie", "Histoire", "Technologie de la coiffure", "Technologie des soins esthétiques",
                     "Cosmétologie", "Education plastique", "Ethique et déontologie", "Mathématique", "Physique appliquée", 
                     "Chimie appliquée", "Biologie appliquée", "Anglais", "Français"
                ]
            },
            {
                option: "Coiffure",
                cours: [
                    "Civisme", "Questions d’Actualité", "Histoire de la coiffure", "Soins de beauté", 
                    "Coupe cheveux", "Coloration / décoloration", "Education plastique", "Soutien de coiffure",
                    "Mathématique", "Biologie appliquée", "Gestion et administration", "Anglais", "Français"
                ]
            }
        ]
    },

    {
        section: "Techniques Professionnelles",
        options: [
            {
                option: "Coupe et Couture",
                cours: [
                    "Actualité", "Civisme", "Législation sociale", "Technologie de la spécialité",
                    "Technologie des textiles", "Organisation du travail", "Biologie", "Mathématique",
                    "Anglais", "Français"
                ]
            },
            {
                option: "Hôtesse d'Acceuil",
                cours: [
                    "Civisme", "Géographie", "Histoire", "Esthétique", "Législation sociale", 
                    "Organisation du bureau", "Psychologie sociale", "Economie de l’entreprise", 
                    "Economie financière", "Finances publiques et droit fiscal", "Physique", "Secourisme", 
                    "Anglais", "Français"
                ]
            },
            {
                option: "Hôtelerie et Restauration",
                cours: [
                    "Civisme", "Géographie touristique", "Histoire", "Technologie hôtelière", "Technologie du restaurant",
                    "Œnologie et boissons", "Comptabilité hôtelière", "Diététique", "Mathématique", "Physique", "Chimie", 
                    "Microbiologie", "Anglais", "Français"
                ]
            },
            {
                option: "Hebergement",
                cours: [
                    "Actualité", "Civisme", "Technologie hôtelière", "Technologie culinaire", "Technologie du restaurant",
                    "Hygiène alimentaire et nutrition", "Législation sociale", "Comptabilité hôtelière", "Biologie", 
                    "Mathématique", "Anglais", "Français"
                ]
            },
            {
                option: "Tourisme",
                cours: [
                   "Histoire", "Législation sociale et Organisation du tourisme", "Economie générale", 
                   "Psychologie sociale", "Tourisme", "Biologie animale", "Conservation des ressources naturelles", 
                   "Sociologie et animation des loisirs", "Mathématique", "Photographie", 
                   "Organisation des entreprises", "Informatique", "Géographie touristique", 
                   "Anglais", "Français"
                ]
            },
        ]
    },

    {
        section: "Techniques Agricole",
        options: [
            {
                option: "Agriculture Générale",
                cours: [
                     "Histoire", "Géographie", "Civisme", "Agriculture", "Zootechnie", 
                     "Amélioration des plantes cultivé", "Agrostologie", "Développement rural", 
                     "Génie rural", "Entrepreneuriat", "Phytopathologie", "Technologie agricole",
                     "Chimie", "Physique", "Mathématique", "Anglais", "Français"
                ]
            },
            {
                option: "Pêche et Navigation",
                cours: [
                    "Histoire", "Géographie", "Civisme", "Technologie/génie", "Pisciculture",
                    "Ecologie", "Pêche", "Ichtyo pathologie", "Navigation", "Ichtyologie", 
                    "Développement rural", "Conservation", "Entrepreneuriat", "Chimie", "Physique",
                    "Mathématique", "Anglais", "Français"
                 ]
            },
            {
                option: "Vétérinaire",
                cours: [
                     "Histoire", "Géographie", "Civisme", "Maladies parasitaires", "Technologie (expertise)",     
                     "Maladies infectieuses", "Hygiène", "Pathologie", "Chirurgie", "Obstétrique",
                     "Cultures fourragères", "Pharmacologie", "Développement rural", "Zootechnie", "Entrepreneuriat",
                     "Chimie", "Physique", "Mathématique", "Anglais", "Français"
                 ]
            },
            {
                option: "Industries Agricoles",
                cours: [
                     "Histoire", "Géographie", "Civisme", "Technique de labo/chimie", "Microbiologie", 
                     "Conservation des produits agricoles", "Contrôle de fabrication", "Génie industriel", 
                     "Toxicologie", "Développement rural", "Entrepreneuriat", "Chimie", "Physique", 
                     "Mathématique", "Anglais", "Français"
                 ]
            },
            {
                option: "Nutrition",
                cours: [
                   "Histoire", "Géographie", "Civisme", "Diététique","Anatomie & physiologie", "Technologie culinaire",
                   "Enquête nutritionnelle", "Economie alimentaire", "Entrepreneuriat", "Microbiologie", "Chimie", 
                   "Physique", "Mathématique", "Anglais", "Français"
                 ]
            },
            {
                option: "Foresterie",
                cours: [
                   "Histoire", "Géographie", "Civisme", "Aménagement forestier", "Dendrologie", "Industries du bois",
                   "Dendrométrie", "Sylviculture", "Législation forestière", "Inventaire forestier", "Développement rural", 
                   "Exploitation forestière", "Entrepreneuriat", "Pathologie forestière", "Chimie", "Physique",
                   "Mathématique", "Anglais", "Français"
                 ]
            },
        ]
    },
];

export const getOptionAndSection = () => {
    const options: any[] = [];
    sections.forEach(section => {
        section.options.forEach(option => {
            options.push({label: `${section.section} - ${option.option}`, value: `${section.section} - ${option.option}`});
        });
    });

    return options;
}

export const getCoursesFromOptionValue = (optionValue: string) => {
    if(!optionValue) return [];
    const sectionV = optionValue.split(' - ')[0];
    const optionV = optionValue.split(' - ')[1];
    const sectionIndex = sections.findIndex(section => section.section === sectionV);
    const optionIndex = sections[sectionIndex].options.findIndex(option => option.option === optionV);
    return sections[sectionIndex].options[optionIndex].cours;
}