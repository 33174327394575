import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import makeToast, { defaultOptions } from "../../utils/toast";

const baseUrl = "https://bot.harvely.com/api/teacher";

export const useGptForm = (setData: any, tokens = "") => {
  const token = localStorage.getItem("token");
  const queryClient = useQueryClient();
  const GptForm = (data: any) => {

    localStorage.removeItem("situationInProgress");
    return axios.post(
      `${baseUrl}/${data.isFiche ? 'fiche' : ''}`,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  return useMutation(GptForm, {
    onSuccess: (result: AxiosResponse) => {
      setData(result.data?.situation, result.data?.id);
      queryClient.setQueriesData<any>(["situations"], (old: any) => {
        const index = old.findIndex((o: any) => o.id === result.data.id);
        if (index >= 0) {
          old[index] = result.data;
          return old;
        }
        return [...old, result.data];
      });
    },

    onError: (error: AxiosError) => {
      makeToast(
        "Nous n'avons pas pu genérer votre situation, Veuillez réessayer  !",
        defaultOptions
      );
    },
  });
};
