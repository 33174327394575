import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import makeToast, { defaultOptions } from "../../utils/toast";

const baseUrl = "https://bot.harvely.com/api/payments/flexpay";

export const useClassForm = (setData: any, tokens = "") => {
  const queryClient = useQueryClient();
  const paymentForm = (data: any) => {
    const token = localStorage.getItem("token");
    return axios.post(
      baseUrl,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  return useMutation(paymentForm, {
    onSuccess: (result: AxiosResponse) => {
      setData(result.data);
      queryClient.invalidateQueries(["user-profile"]);
    },

    onError: (error: AxiosError) => {
      makeToast(
        "Nous n'avons pas pu traiter votre paiement, Veuillez réessayer ou contacter le service client  !",
        defaultOptions
      );
    },
  });
};
