export const schoolOptions = [
    { value: "mm", label: "CS. Mama Mulezi" },
    { value: "ma", label: "Maendeleo" },
    { value: "kis", label: "K.I.S" },
    { value: "tereza", label: "Tereza Mira" },
    { value: "bethel", label: "CS. Adventiste Bethel" },
    { value: "amani", label: "GS. Amani" },
    { value: "alfajiri", label: "GS. Alfajiri" },
    { value: "mwanga", label: "Inst. Mwanga" },
    { value: "biyayi", label: "Biyayi" },
    { value: "kami", label: "Kami" },
    { value: "lajoie", label: "CS. La Joie" },
    { value: "bakanja", label: "Bakanja" },
    { value: "johana", label: "Johana" },
    { value: "maranatha", label: "Maranatha" },
    { value: "volcans", label: "Les volcans" },
    { value: "concorde", label: "La Concorde" },
    { value: "eib", label: "E.I.B" },
    { value: "masudi", label: "Les Masudi" },
    { value: "hirondelles", label: "Les Hirondelles" },
    { value: "uenezaji", label: "Inst. Uenezaji" },
    { value: "ushindi", label: "Inst. Ushindi" },
    { value: "materdei", label: "GS. Mater Dei" },
  ];