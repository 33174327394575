import {combineReducers} from 'redux';
import {
  FINISHED_VERIFYING_AUTHENTICATION,
  AUTHENTICATION_STATE,
  INITIAL_DATA_LOAD_STATE,
  USER_DATA_LOAD_STATE,
  USER_LOGGED_OUT,
  USER_AUTH_SIGN_METHOD_STATE,
  SET_NETWORK_CONNECTION_STATE,
  SET_TOKEN,
  SIGNUP_DATA,
} from './actions';

const APP_INTIAL_STATE = {
  didSplashAnimationFinish: false,
  didVerifyingAuthenticationFinish: false,
  isAuthenticated: false,
  isInitialDataLoaded: false,
  isUserDataLoaded: false,
  isSignedUp: false,
  isOnline: true,
  signupData: {},
  token: '',
  paymentData: {},
};

type ReducerAction = {type: string; payload: any};

const appStateReducer = (state = APP_INTIAL_STATE, action: ReducerAction) => {
  switch (action.type) {
    case FINISHED_VERIFYING_AUTHENTICATION:
      return {
        ...state,
        didVerifyingAuthenticationFinish: true,
      };
    case AUTHENTICATION_STATE:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case INITIAL_DATA_LOAD_STATE:
      return {
        ...state,
        isInitialDataLoaded: action.payload,
      };
    case USER_DATA_LOAD_STATE:
      return {
        ...state,
        isUserDataLoaded: action.payload,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case USER_AUTH_SIGN_METHOD_STATE:
      return {
        ...state,
        isSignedUp: action.payload,
      };
    case USER_LOGGED_OUT:
      return {
        didSplashAnimationFinish: true,
        didVerifyingAuthenticationFinish: true,
        isAuthenticated: false,
        isInitialDataLoaded: false,
        isUserDataLoaded: false,
        isSignedUp: false,
      };
    case SET_NETWORK_CONNECTION_STATE:
      return {
        ...state,
        isOnline: action.payload,
      };
    case 'SIGNUP_DATA':
      return {
        ...state,
        signupData: {
          ...state.signupData,
          ...action.payload,
        },
      };
    case SIGNUP_DATA:
      return {
        ...state,
        signupData: {
          ...state.signupData,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

const reducer = combineReducers({
  appState: appStateReducer,
});

export default reducer;
