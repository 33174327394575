import { useMutation } from "@tanstack/react-query";
import { postApi } from "../../../../utils/api";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";

export const useLogin = (navigate: any, setStatus?: any) => {
  const login = (data: any) => {
    return postApi(!data.idToken ? "v1/auth/email/login" : "v1/auth/google/login", data);
  };
  return useMutation(login, {
    onSuccess: (result: AxiosResponse) => {
      if (result.status === 200) {
        localStorage.setItem("token", result?.data?.token);
        navigate("/");
        window.location.reload();
      }
    },
    onError: (error: AxiosError<any>) => {
      // toast(
      //   "Échec de la connexion, Veuillez bien vérifier vos identifiants !",
      //   {
      //     type: "error",
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //   }
      // );
      if (error?.response?.data?.errors?.email) setStatus?.("error_email");
      if (error?.response?.data?.errors?.password)
        setStatus?.("incorrectPassword");
    },
  });
};
