import Input from "../../../components/input";
import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import validators from "./validators";
import Button from "../../../components/button";
import { useLogin } from "./hooks/index";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../../assets/logo.svg";
import { useSignup } from "../signup/hooks";
import { useGoogleLogin } from "@react-oauth/google";

interface IFormInput {
  email: string;
  password: string;
  fullName: string;
  passwordConfirm: string;
  passwordLogin: string;
}

const Login: React.FC = () => {
  const { register, handleSubmit, watch } = useForm<IFormInput>();
  const [status, setStatus] = useState<string>("");
  const [error, setError] = useState("");
  const [showPasswordError, setShowPasswordError] = useState(false);

  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      mutation.mutate({
        idToken: tokenResponse.access_token,
      });
    },
  });

  const navigate = useNavigate();
  const mutation = useLogin(navigate, (newStatus: string) => {
    if (newStatus === "incorrectPassword" && step === 0) {
      setStep(1);
    } else if (newStatus === "incorrectPassword" && step !== 0) {
      setShowPasswordError(true);
    }

    if (newStatus === "error_email") {
      setStep(2);
    }
  });
  const mutationSignup = useSignup(navigate, (error: string) => {
    if (error) {
      setError("Nous n'avons pas pu creer votre compte, veuillez reesayer !");
    }
  });
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegex = /^(\+\d{1,3})?\d{6,16}$/;
  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    setShowPasswordError(false);
    setError("");
    if (
      step === 0 &&
      !emailRegex.test(data.email) &&
      !phoneRegex.test(data.email)
    ) {
      setError(
        "Veuillez entrer une addresse email ou un numero de telephone correct"
      );
      return;
    }

    if (step === 2 && !data.fullName) {
      setError("Veuillez entrer votre nom complet");
      return;
    }

    if (step === 3 && !data.password) {
      setError("Veuillez entrer votre mot de passe");
      return;
    }

    if (step === 3 && !(data.password === data.passwordConfirm)) {
      setError(
        "Vos deux mot de passe ne correspondent pas, veuillez entrer encore votre mot de passe"
      );
      // setStep(step - 1);
      return;
    }

    if (step < 2) {
      mutation.mutate({
        email: data.email,
        password: data.passwordLogin,
      });
    } else if (step === 3) {
      mutationSignup.mutate({
        password: data.password,
        email: emailRegex.test(data.email) ? data.email : undefined,
        firstName: data.fullName?.split(" ")[0],
        lastName: data.fullName?.split(" ")[1]
          ? data.fullName?.split(" ")[1]
          : data.fullName?.split(" ")[0],
        phoneNumber: phoneRegex.test(data.email) ? data.email : undefined,
        passwordConfirmation: data.password,
      });
    } else {
      setStep(step + 1);
    }
  };

  const [step, setStep] = useState(0);
  const location = useLocation();
  const course = new URLSearchParams(location.search).get("course");
  const lesson = new URLSearchParams(location.search).get("lesson");
  const areaOfLife = new URLSearchParams(location.search).get("areaOfLife");
  const previousLesson = new URLSearchParams(location.search).get("previousLesson");

  useEffect(() => {
    if (course && lesson && areaOfLife) {
      localStorage.setItem(
        "situationInProgress",
        JSON.stringify({ course, lesson, areaOfLife, previousLesson })
      );
    }
  }, []);

  return (
    <div className="flex flex-row h-screen bg-[#FFF7F1] items-center justify-center">
      <div className="rounded-[15px] p-8 md:w-[40%] lg:w-[35%] sm:w-[90%] self-center bg-[#FFE8DE]">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-[100%] flex flex-col snap-center"
        >
          <img src={Logo} className="mx-auto h-[50px] mb-5" />
          {showPasswordError && (
            <p className="bg-[#9F0E0E] text-[#fff] text-[13px] px-4 py-2 rounded-[10px] text-center">
              Vous avez entrer des identifiants incorrect, avez vous oublié
              votre mot de passe ?{" "}
              <a
                href="/forgetpassword"
                className="text-[#ffffff] font-bold underline"
              >
                Cliquez ici pour le reinitialiser
              </a>{" "}
              ou{" "}
              <span
                className="font-bold cursor-pointer underline"
                onClick={() => {
                  setShowPasswordError(false);
                  setStep(0);
                }}
              >
                cliquez ici
              </span>{" "}
              pour vous connecter avec un autre numero de telephone ou addresse
              email
            </p>
          )}
          {error && (
            <p className="bg-[#9F0E0E] text-[#fff] text-[13px] px-4 py-2 rounded-[10px] text-center">
              {error}
            </p>
          )}
          <div className={`${step === 0 ? "h-fit" : "h-0 overflow-hidden"}`}>
            <p className="my-6 mx-auto text-center">
              Quel est votre addresse email ou numero de telephone ?
            </p>
            <Input
              name="email"
              validator={validators["email"]}
              label=""
              placeholder="Ex: example@gmail.com ou 243990445626"
              register={register}
              inputStyle="bg-[#FFD2BF] border-[0px] text-[#000] text-center text-[16px]"
            />
          </div>
          <div className={`${step === 1 ? "h-fit" : "h-0 overflow-hidden"}`}>
            <p className="my-6 mx-auto text-center">
              Quel est votre mot de passe ou code d'acces ?
            </p>
            <Input
              type="password"
              name="passwordLogin"
              validator={validators["password"]}
              label=""
              placeholder="Ex: example$20"
              register={register}
              inputStyle="bg-[#FFD2BF] border-[0px] text-center"
            />
          </div>

          <div className={`${step === 2 ? "h-fit" : "h-0 overflow-hidden"}`}>
            <p className="my-6 mx-auto text-center">
              Quel est votre nom complet ( Nom , Post nom, Prenom) ?
            </p>
            <Input
              name="fullName"
              label=""
              placeholder="Ex: Metre Bacibone Dug"
              register={register}
              inputStyle="bg-[#FFD2BF] border-[0px] text-[#000] text-center text-[16px]"
            />
          </div>

          <div className={`${step === 3 ? "h-fit" : "h-0 overflow-hidden"}`}>
            <p className="my-6 mx-auto text-center">
              Creer un mot de passe pour votre compte
            </p>
            <Input
              name="password"
              label=""
              placeholder="Ex: Metre Bacibone Dug"
              register={register}
              type="password"
              inputStyle="bg-[#FFD2BF] border-[0px] text-[#000] text-center text-[16px]"
            />
          </div>

          <div className={`${step === 3 ? "h-fit" : "h-0 overflow-hidden"}`}>
            <p className="my-6 mx-auto text-center">
              Repetez votre mot de passe
            </p>
            <Input
              name="passwordConfirm"
              label=""
              type="password"
              placeholder="...."
              register={register}
              inputStyle="bg-[#FFD2BF] border-[0px] text-[#000] text-center text-[16px]"
            />
          </div>
          {(step === 1 || step === 0) && <a href="/forget" className="font-semibold mt-3 text-[16px] text-center">Mot de passe oublie?</a>}

          <Button
            isLoading={mutation.isLoading || mutationSignup.isLoading}
            label={
              mutation.isLoading || mutationSignup.isLoading
                ? "..."
                : step === 0 || (step > 1 && step < 4)
                ? "Continuer"
                : step === 1
                ? "Se connecter"
                : "Creer un compte"
            }
            height="h-[40px]"
            textColor="white"
            isSubmit
          />

          <button
            aria-label="Sign in with Google "
            className="flex items-center bg-white border border-button-border-light justify-center rounded-md p-0.5 pr-3 mt-3"
            onClick={() => login()}
          >
            <div className="flex items-center justify-center bg-white w-9 h-9 rounded-l">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-5 h-5">
                <title>Se Connecter avec Google</title>
                <desc>Google G Logo</desc>
                <path
                  d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                  className="fill-google-logo-blue"
                ></path>
                <path
                  d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                  className="fill-google-logo-green"
                ></path>
                <path
                  d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                  className="fill-google-logo-yellow"
                ></path>
                <path
                  d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                  className="fill-google-logo-red"
                ></path>
              </svg>
            </div>
            <span className="text-sm text-google-text-gray tracking-wider">Sign in with Google</span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
