import React from "react";
import { Formik } from "formik";
import Input from "../../components/input";
import InputDropdown from "../../components/inputDropdown";
import Button from "../../components/button";
import { useState } from "react";
import { usePaymentForm } from "./mutation";
import { LoadingIcon } from "../../components/loader";
import { useUserProfile } from "../profile/hooks";

const PriceForm: React.FC<{paymentData: any}> = ({paymentData}) => {
  const [selectedOption, setSelectedOption] = useState<any>();
  const [paymentMethod, setPaymentMethod] = useState<any>("");
  const [resultData, setResultData] = useState<any>(null);
  const mutation = usePaymentForm((data: any) => {
    setResultData(data);
    setSelectedOption(undefined);
    localStorage.removeItem("paymentData");
  });
  const [showNotice, setShowNotice] = useState<boolean>(false);
  const {data: user} = useUserProfile();


  return (
    <div>
     

      <div className="md:w-[500px] sm:w-full sm:min-w-[300px] overflow-y-scroll no-scrollbar">
      {mutation.isLoading && (
        <div className=" bg-secondary50 bg-opacity-95 fixed w-full h-full z-50 text-lg text-left">
          <p className="font-bold">
            Veuillez vérifier votre téléphone pour insérer
            <br />
            le code de confirmation.
          </p>
          <p className="mt-10">
            Nous sommes en train de traiter votre demande. Veuillez faire preuve
            de patience
            <br />
            et ne fermez pas cette fenêtre !
          </p>
          <div className="ml-[10px] mt-10">
            <LoadingIcon height={24} />{" "}
          </div>
        </div>
      )}
      {!mutation.isLoading && <p className="text-lg text-primary-400 mb-5">
        Vous êtes sur le point de payer{" "}
        <span className="font-bold">
        {paymentData?.paymentType === 'SUBSCRIPTION' ? paymentData?.subscriptionLength :paymentData?.tokenPurchased} {paymentData?.paymentType === 'SUBSCRIPTION' ? 'mois d\'abonnement' : 'Fiches non expirable'} 
        </span>
      </p>}
      <Formik
        initialValues={{
          amount: selectedOption?.price?.toString(),
          currency: "USD",
        }}
        onSubmit={(values) => {
          const payment = {
            ...values,
            ...paymentData,
            amount: '0',
          };
          mutation.mutate(payment);
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <div className="flex flex-col gap-5 verflow-y-scroll no-scrollbar">
            {!mutation.isLoading && resultData?.status === "success" && (
              <div className="bg-[#CAFFD9] rounded-[10px] p-3 flex flex-row justify-center items-center">
                <p className="text-md text-center">
                  Merci, votre paiement a été reçu ! Nous vous remercions
                  également d'avoir choisi notre service.
                </p>
              </div>
            )}
            {!mutation.isLoading && resultData?.status === "failed" && (
              <div className="bg-[#FFCACA] rounded-[10px] p-3 flex flex-row justify-center items-center">
                {user?.role?.name.toLowerCase() !== 'admin' ?<p className="text-md text-center">
                  Oups, le paiement a échoué. Veuillez nous contacter à
                  l'adresse e-mail <b>admin@dugassistant.com</b> pour une
                  assistance ou réessayer{" "}
                </p> : <p className="text-md text-center">
                  Oups, le paiement a échoué. Veuillez verifier les informations de l utilisateur ou reessayer{" "}
                </p>}
              </div>
            )}
            {!(!mutation.isLoading && resultData?.status === "success") && (
              <>
                {user?.role?.name.toLowerCase() === 'admin' && <Input
                  onChange={handleChange("username")}
                  icon=""
                  label={"Rechercher un utilisateur"}
                  placeholder={""}
                  inputStyle="rounded-[5px]"
                  style="mt-5"
                  name={"search"}
                />}
                <InputDropdown
                  label="Devise (USD, CDF)"
                  placeholder="Selectionner"
                  items={[
                    { value: "USD", label: "USD" },
                    { value: "CDF", label: "CDF" },
                  ]}
                  onSelect={function (item: string): void {
                    setFieldValue("currency", item);
                  }}
                />
                <InputDropdown
                  label="Methode de paiement"
                  placeholder="Selectionner"
                  items={[{ value: "MOBILEMONEY", label: "MOBILE MONEY" }]}
                  onSelect={function (item: string): void {
                    setFieldValue("chanel", item);
                    setPaymentMethod(item);
                  }}
                />
                {paymentMethod === "MOBILEMONEY" && (
                  <>
                    <InputDropdown
                      label="Operateur telephonique"
                      placeholder="Selectionner"
                      items={[
                        { value: "MPESA", label: "VODACOM" },
                        { value: "ORANGE", label: "ORANGE" },
                        { value: "AIRTEL", label: "AIRTEL" },
                      ]}
                      onSelect={function (item: string): void {
                        setFieldValue("provider", item);
                      }}
                      selectedItem={user?.role?.name.toLowerCase() === 'admin' ? 'MPESA' : undefined}
                    />
                    <Input
                      onChange={handleChange("phoneNumber")}
                      icon=""
                      label={"Numero de telephone (+243xxx)"}
                      placeholder={""}
                      inputStyle="rounded-[5px]"
                      style="mt-5"
                      name={"phoneNumber"}
                      defaultValue={user?.role?.name.toLowerCase() === 'admin' ? '243990445626' : undefined}
                    />
                  </>
                )}
                {showNotice && (
                  <div className="p-3 bg-yellow rounded-[10px]">
                    {user?.role?.name.toLowerCase() !== 'admin' ? <p className="text-sm">
                      Assurez-vous d'avoir en main le téléphone équipé de la
                      carte SIM contenant le <b>numéro que vous avez saisi</b>.
                      Nous allons établir un contact avec votre téléphone pour
                      que vous puissiez entrer votre code.
                      <br />
                      Veuillez noter que vous disposerez de 90 secondes pour
                      insérer votre code.
                    </p> : <p className="text-sm">Veuillez relire les informations avant d'activer les credits</p>}
                    <div className="w-[300px]">
                      <Button
                        label="J ai compris, proceder au paiement"
                        height="h-[35px] rounded-[5px] self-start w-[300px] m-0 "
                        onClick={handleSubmit}
                      />
                    </div>
                  </div>
                )}

                {!showNotice && (
                  <div className="w-[150px]">
                    <Button
                      label={"Payer"}
                      height="h-[44px] rounded-[5px] self-start w-[150px] m-0 "
                      textColor="white"
                      onClick={() => {
                        setShowNotice(true);
                      }}
                      isLoading={mutation.isLoading}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </Formik>
    </div>
    </div>
  );
};

export default PriceForm;
