import React, { useEffect, useState } from "react";
import { ImageFiche, useFicheImageQuery, useFicheWithAiMutation, useFicheWithAiQuery, useGptFicheForm } from "./hook";
import { LoadingIcon } from "../../../components/loader";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import { Search } from "react-feather";

export type ImageViewerProps = {
    prompt: string;
    ficheId: string;
    images?: ImageFiche[];
    aiImageApiLink?: string;
    aiImages?: string[];
};

export const AiImage: React.FC<{url?: string, alt: string}> = (props) => {
    return (
        <div className="inline-block">
            {props.url && <img src={props.url} alt={props.alt} className="mr-3 cursor-pointer object-cover rounded-sm h-[100px] w-[100px] z-10" />}
            {!props.url && <div className="bg-[#fff] w-[100px] h-[100px] rounded-md shadow-md flex items-center justify-center">
                <LoadingIcon height={30} />
            </div>}
        </div>
    )
}

export const ImageViewer: React.FC<ImageViewerProps> = (props) => {
    const [searchImage, setSearchImage] = useState('');
    const [search, setSearch] = useState('');
    const {data, isLoading} =  useFicheImageQuery(search, props.ficheId);
    const [selected, setSelected] = useState<ImageFiche[]>([]);
    const [showDialog, setShowDialog] = useState(false);
    const [awaitingImages, setAwaitingImages] = useState<boolean>(props.aiImageApiLink && props.aiImageApiLink?.length > 4 ? true : false);
    const [attempt, setAttempt] = useState(0);

    const mutation = useGptFicheForm(() =>{
        // console.log('mutation');
        setShowDialog(false);
    })

    const mutationAi = useFicheWithAiMutation(() => {
        setAwaitingImages(true);
    });
    const {data: dataImages, isLoading: fetchingImages} = useFicheWithAiQuery(props.ficheId, attempt, props.aiImageApiLink);

    useEffect(() => {
        if(dataImages?.length) {
            setAwaitingImages(false);
        } else {
            setTimeout(() => {
                setAttempt(attempt + 1)
            }, 40000);
        }
    }, [dataImages, attempt])

    if(props.images) {
        return (
            <div className="w-full mt-3">
            {props.images.map((image) => (
                <div key={image.url} className="inline-block">
                    <img  src={image.url} alt={image.alt} className="mr-3 cursor-pointer object-cover rounded-sm h-[100px] w-[100px] z-10" />
                </div>
            ))}
            </div>
        )
    }

    if(!showDialog) return (
        <button type="button" className="bg-[#0E9F90] text-[#000] px-5 py-2 mt-5 w-fit rounded-md my-3" onClick={() => setShowDialog(true)}>
            Choisir une illustration
        </button>
    )

    return (
        <div className="bg-[#000]/20 fixed w-full h-full top-0 left-0 p-5 md:p-10 z-[90000] overflow-y-scroll">

            <div className="bg-[#fff] w-full md:w-3/4 p-5 mx-auto my-auto rounded-md">
            <p className="text-primary-500 text-[15px] font-medium py-2">
                <BsFillExclamationCircleFill className="text-primary-500 text-[16px] inline-block mr-2" />
                Veuillez decrire votre image, precisez les details que vous souhaitez voir sur l'image. Choisissez, et cliquez ensuite sur suivant pour continuer.
            </p>
            {(!mutationAi.isLoading && !awaitingImages) && <div className="flex flex-row items-center gap-3">
                <input type="text" value={searchImage} onChange={(e) => setSearchImage(e.target.value)} placeholder="Decrivez votre image ici" className="w-full p-2 rounded-md grow max-w-[250px] border-[1px] border-primary-400 h-[40px] my-auto" />
                <button type="button" className="bg-[#0E9F90] text-[#000] px-5 py-2 w-fit rounded-md my-3" onClick={() => {
                    setSearch(searchImage);
                    mutationAi.mutate({prompt: searchImage, ficheId: props.ficheId})
                }}> <Search  color="#fff"/> {isLoading && <LoadingIcon height={16} />}</button>
            </div>
            }
                {(props.aiImages?.length || data?.length) && <h1 className="my-4">Choisissez une ou plusieurs illustration</h1>}
                {isLoading && <LoadingIcon />}
                <div className="grid grid-cols-2 md:grid-cols-4 gap-5 mt-5">
                    {(awaitingImages || fetchingImages) && <div className=" shadow-sm p-4 bg-primary-500/20 my-2 col-span-4 rounded-md" >
                        Nous creons des images adaptées à votre leçon avec intelligence artificielle, cela peut prendre 30 secondes A 2 minutes. Veuillez patienter un peu svp...
                    </div>  }

                    {( props.aiImages?.length ? props.aiImages : dataImages)?.map((image) => ({url: image, alt: 'image ai'}))?.map(image => (
                        <div key={image.url}>
                            <input type="checkbox" className="absolute w-[25px] h-[25px] z-20" checked={selected.find(s => s.url === image.url) ? true : false} onChange={(checked) => {
                                if(!checked.target.checked) return setSelected(selected.filter((img) => img.url !== image.url));
                                setSelected([...selected, image])
                            }} />
                            <img onClick={() => {
                                if(selected.find(img => img.url === image.url)) return setSelected(selected.filter((img) => img.url !== image.url));
                                setSelected([...selected, image])
                            }} src={image.url} alt={image.alt} className=" cursor-pointer object-cover rounded-sm h-[150px] z-10" />
                        </div>
                    ))}

                    {!(isLoading || fetchingImages) && data?.map((image) => (
                        <div key={image.url}>
                            <input type="checkbox" className="absolute w-[25px] h-[25px] z-20" checked={selected.find(s => s.url === image.url) ? true : false} onChange={(checked) => {
                                if(!checked.target.checked) return setSelected(selected.filter((img) => img.url !== image.url));
                                setSelected([...selected, image])
                            }} />
                            <img onClick={() => {
                                if(selected.find(img => img.url === image.url)) return setSelected(selected.filter((img) => img.url !== image.url));
                                setSelected([...selected, image])
                            
                            }}  src={image.url} alt={image.alt} className=" cursor-pointer object-cover rounded-sm h-[150px] z-10" />
                        </div>
                    ))}
                </div>
                <button type="button" className="bg-[#000] text-[#fff] px-5 py-2 mt-5" onClick={() =>{
                    setShowDialog(false);
                }}>
                    {mutation.isLoading ? <LoadingIcon /> : 'Ignorer'}
                </button>
                <button type="button" className="bg-[#0E9F90] text-[#000] px-5 py-2 mt-5" onClick={() =>{
                    if(selected.length === 0 || mutation.isLoading) return;
                    mutation.mutate({id: `saved-images/${props.ficheId}`, images: selected})
                }}>
                    {mutation.isLoading ? <LoadingIcon /> : 'Enregistrer'}
                </button>
            </div>
        </div>
    );
}