import { XMLParser } from 'fast-xml-parser';

export const parseXML = (xml: string) => {
    const options = {
        attributeNamePrefix: "",
        ignoreAttributes: false,
        parseNodeValue: true,
        parseAttributeValue: true,
        trimValues: true,
        ignoreNameSpace: true,
    };
    return (new  XMLParser()).parse(xml);
}

export const regexImage = /<image>(.*?)<\/image>/;
export const regexContent = /<explanation>(.*?)<\/explanation>/g;
export const regexMaterial = /<material>(.*?)<\/material>/g;

const extractExtraSearchTerm = (text: string,  index = 0) => {
    const pattern = /<[^>]+>([^<]+)<\/[^>]+>/g;

    let matches = [];
    let match;

    while ((match = pattern.exec(text)) !== null) {
        matches.push(match[1]);
    }

    if (matches.length >= 3) {
        return matches[index];
    } else {
        return null;
    }
}

export const extractSearchTerm = (text: string, regex: any, index = 0) => {
    return text;
    const match = regex.exec(text);
    if (match && match.length > 1) {
        return match[1]; // The search term is captured in the first group
    }

    return extractExtraSearchTerm(text, index);
};

export function blobToBase64(blob: Blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }