import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import makeToast, { defaultOptions } from "../../../utils/toast";

const baseUrl = "https://bot.harvely.com/api/fiches";

export interface Fiche {
    id: string;
    subject: string;
    classe: string;
    lessonTitle: string;
    previousLesson: string | null;
    areaOfLife: string;
    currentStep: number;
    revisionTeacher: string;
    revisionStudent: string;
    objectives: string;
    situation: string;
    activitePrincipaleTeacher: string;
    activitePrincipaleStudent: string;
    syntheseTeacher: string;
    syntheseStudent: string;
    exercice: string;
    situationSimilaire: string;
    createdAt: Date;
    updatedAt: Date;
    numberOfToken: number;
    model: string;
    status: 'in progress' | 'new' | 'restarted' | 'completed';
    userId: number;
    revisionTeacherRappel: string;
    revisionStudentRappel: string;
    revisionTeacherMotivation: string;
    revisionStudentMotivation: string;
    resumeTeacher: string;
    resumeStudent: string;
    activiteControleApplicationTeacher: string;
    activiteControleApplicationStudent: string;
    activiteControleResearchTeacher: string;
    activiteControleResearchStudent: string;
    activiteControleEvaluationTeacher: string;
    activiteControleEvaluationStudent: string;
    images: ImageFiche[];
    aiImageApiLink: string;
    aiImages: string[];
  }
  

export const useGptFicheForm = (setData: any, tokens = "") => {
  const token = localStorage.getItem("token");
  const queryClient = useQueryClient();
  const GptForm = (data: any) => {
    localStorage.removeItem("situationInProgress");
    return axios.post(
      `${baseUrl}/${data.id ? data.id : ''}?retry=${data.retry ? 'true' : 'false'}`,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  return useMutation(GptForm, {
    onSuccess: (result: AxiosResponse) => {
      queryClient.setQueryData<any>(["fiches", {id: result.data.id}], () => {
        return result.data;
      });

      if(!result.data.revisionTeacher) {
        setData(result.data);
        window.location.replace(`/fiches/${result.data.id}`); 
      }
    },

    onError: (error: AxiosError) => {
      makeToast(
        "Nous n'avons pas pu genérer votre situation, Veuillez réessayer  !",
        defaultOptions
      );
    },
  });
};

export const generateDocxFiche = async (id: string) => {
  const token = localStorage.getItem("token");
  const result = await axios.get(`https://bot.harvely.com/api/fiches/${id}/word`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data;

}

export const useFicheMutation = () => {
  return useMutation(generateDocxFiche, {
    onSuccess: (result: any) => {
      const link = document.createElement('a');
      link.href = `https://bot.harvely.com${result.url}.docx`;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
    },
    onError: (error: AxiosError) => {
      makeToast(
        "Nous n'avons pas pu générer le document, Veuillez réessayer  !",
        defaultOptions
      );
    },
  });
};


export const useFicheQuery = (id: string) => {
  return useQuery(
    ["fiches", {id}],
    async (): Promise<Fiche> => {
      const token = localStorage.getItem("token");
        try {
          const result = await axios.get(`https://bot.harvely.com/api/fiches/${id}${token ? '' : '/public'}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if(!result.data && !token) {
            window.location.replace('/login');
          }
          return result.data || {};
        } catch (error) {
          if(!token) {
            window.location.replace('/login');
          }
          return {} as Fiche;
        }
    },
    // {
    //   staleTime: 10 * (60 * 1000), // 10 mins
    //   cacheTime: 15 * (60 * 1000), // 15 mins
    // }
  );
};

export type ImageFiche = {
  url: string;
  alt?: string;
  thumbnail?: string;
  name?: string;
  width?: number;
  height?: number;
}

export const useFicheImageQuery = (prompt: string, ficheId: string) => {
  return useQuery(
    ["fiches-images", {ficheId, prompt}],
    async (): Promise<ImageFiche[]> => {
      const token = localStorage.getItem("token");
      if(!prompt) return [];
      const result = await axios.post(`https://bot.harvely.com/api/fiches/images/${ficheId}?search=${prompt}`, {
        search: prompt,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
      return result.data || [];
    },
    // {
    //   staleTime: 10 * (60 * 1000), // 10 mins
    //   cacheTime: 15 * (60 * 1000), // 15 mins
    // }
  );
};

export const useFicheWithAiMutation = (callback: any) => {
  return useMutation((data: any) => {
    const token = localStorage.getItem("token");
    return axios.post(`https://bot.harvely.com/api/fiches/generate-image`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }, {
    onSuccess: (result: AxiosResponse) => {
      //window.location.replace(`/fiches/${result.data.id}`);
      makeToast(
        "Nous sommes entrain de generer les images avec l'IA!",
        defaultOptions
      );

      callback && callback();
    },
    onError: (error: AxiosError) => {
      makeToast(
        "Nous n'avons pas pu generer l'image!",
        defaultOptions
      );
    },
  });
};

export const useFicheWithAiQuery = (id: string, attempt: number, aiImageApiLink?: string) => {
  return useQuery(
    ["fiches-ai", {id, attempt}],
    async (): Promise<Array<string>> => {
      if(!aiImageApiLink || aiImageApiLink?.length < 5) return [];
      try {
      const token = localStorage.getItem("token");
      const result = await axios.get(`https://bot.harvely.com/api/fiches/generate-image/result?ficheId=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return result.data || {};
    } catch (error) {
      return [];
    }
    },
    // {
    //   staleTime: 10 * (60 * 1000), // 10 mins
    //   cacheTime: 15 * (60 * 1000), // 15 mins
    // }
  );
}

export const useFicheListQuery = (limit: number, page: number) => {
  return useQuery(
    ["fiches", {limit, page}],
    async (): Promise<[Fiche[], number]> => {
      const token = localStorage.getItem("token");
      const result = await axios.get(`https://bot.harvely.com/api/fiches`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          limit,
          page,
        }
      });
      return result.data || {error: 'token'};
    },
    // {
    //   staleTime: 10 * (60 * 1000), // 10 mins
    //   cacheTime: 15 * (60 * 1000), // 15 mins
    // }
  );
};