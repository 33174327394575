export const domains = [
    {label: 'Conjugation', value: 'conjugaison', courses: ['english', 'anglais']},
    {label: 'Reading for comprehension', value: 'comprehension', courses: ['english', 'anglais']},
    {label: 'vocabulary', value: 'vocabulary', courses: ['english', 'anglais']},
    {label: 'Grammar', value: 'grammar', courses: ['english', 'anglais']},
    {label: 'Lecture', value: 'comprehension', courses: ['francais', 'french', 'français']},
    {label: 'Analyse lexical', value: 'analyseLexical', courses: ['francais', 'french', 'français']},
    {label: 'Conjugaison', value: 'conjugaison', courses: ['francais', 'french', 'français']},
    {label: 'Orthographe', value: 'Orthographe', courses: ['francais', 'french', 'français']},
    {label: 'Grammaire', value: 'grammar', courses: ['francais', 'french', 'français']},
    {label: 'Phonétique', value: 'phonetique', courses: ['francais', 'french', 'français']},
    {label: 'Redaction', value: 'redaction', courses: ['francais', 'french', 'français']},
    {label: 'Literature', value: 'literature', courses: ['francais', 'french', 'français']},
    
]

export const courses = [
    {label: 'Exploratoire', level: ['maternelle'], subdomain: ["Decouverte de l'environnement", 'Eveil scientifique']},
    {label: 'Language', level: ['maternelle'], subdomain: ['Recitation, Conte, Saynette', 'Pre-lecture', 'Elocution']},
    {label: 'Mathematiques', level: ['maternelle'], subdomain: ['Nombre & Sens des operations']},
    {label: 'Sensorielle', level: ['maternelle'], subdomain: ['Excercice tactil', 'Exercice visuel', 'Excercice olfactif', 'Exercice gustatif', 'Exercice auditif', 'Exercice kinesthesique']},
    {label: 'Vie pratique', level: ['maternelle'], subdomain: ['Jeux de metier', 'Jeux menager']},
    {label: 'Comportement', level: ['maternelle'], subdomain: ['Education civique', 'Education morale', 'Education Religieuse']},
    {label: 'Promotion de la sante', level: ['maternelle'], subdomain: ['Education a la sante', "Education a l'hygienne", 'Education a la nutrition']},
    {label: 'Musicale', level: ['maternelle'], subdomain: ['Chant', 'Orchestration', 'Ronde']},
    {label: 'Physique', level: ['maternelle'], subdomain: ['Gymnastique']},
    {label: 'Psychomotrice', level: ['maternelle'], subdomain: ['Schema corporel', 'Structuration spatiale', 'Orientation temporelle', 'Lateral']},
    {label: 'Art plastiques', level: ['maternelle'], subdomain: ['Graphique', 'Manuel']},
    {label: 'Libre (Jeux dans les coins)', level: ['maternelle'], subdomain: ['Chambre', 'Bureau', 'Garage', 'Cuisine']},
    
    {label: 'Anatomie', level: ['primaire', 'ctb']},
    {label: 'Anglais', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Biologie', level: ['secondaire']},
    {label: 'Botanique', level: ['primaire', 'ctb']},
    {label: 'Chimie', level: ['secondaire']},
    {label: 'Comptabilité', level: ['secondaire']},
    {label: 'Dessin', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Économie', level: ['secondaire']},
    {label: 'Education A la vie', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Éducation civique', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Éducation physique', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Éducation religieuse', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Études environnementales', value: 'Études environnementales'},
    {label: 'Études sociales', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Français', level: ['primaire', 'ctb', 'secondaire'], subdomain: []},
    {label: 'Géographie', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Histoire', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Informatique', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Kiswahili', level: ['primaire'], subdomain: ['Usemi', 'Usomi']},
    {label: 'Mathématiques', level: ['primaire', 'ctb', 'secondaire'], subdomain: ['Algèbre', 'Analyse', 'Arithmétique', 'Géométrie', 'Logique', 'Statistiques', 'Probabilités']},
    {label: 'Musique', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'organisation des entreprises', level: ['secondaire']},
    {label: 'Physique', level: ['ctb', 'secondaire']},
    {label: 'Sciences Physique', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Zoologie', level: ['primaire', 'ctb', 'secondaire']},
  ]