import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import makeToast, { defaultOptions } from "../../../utils/toast";

const baseUrl = "https://bot.harvely.com/api/resources";

export type Ressource = {
  title: string;
  url: string;
  content: string;
  id: string;
}

export const useGptRessourceForm = (setData: any, tokens = "") => {
  const token = localStorage.getItem("token");
  const queryClient = useQueryClient();
  const GptForm = (data: any) => {
    localStorage.removeItem("situationInProgress");
    return axios.post(
      `${baseUrl}/${data.id ? data.id : ''}?retry=${data.retry ? 'true' : 'false'}`,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  return useMutation(GptForm, {
    onSuccess: (result: AxiosResponse) => {
      setData(result.data?.id);
      queryClient.setQueryData<any>(["ressources", {id: result.data.id}], () => {
        return result.data;
      });
    },

    onError: (error: AxiosError) => {
      makeToast(
        "Nous n'avons pas pu genérer votre situation, Veuillez réessayer  !",
        defaultOptions
      );
    },
  });
};


export const useRessourceQuery = (id: string) => {
  return useQuery(
    ["ressources", {id}],
    async (): Promise<Ressource> => {
      const token = localStorage.ge2tItem("token");
        try {
          const result = await axios.get(`https://bot.harvely.com/api/ressources/${id}${token ? '' : '/public'}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if(!result.data && !token) {
            window.location.replace('/login');
          }
          return result.data || {};
        } catch (error) {
          if(!token) {
            window.location.replace('/login');
          }
          return {} as Ressource;
        }
    },
    // {
    //   staleTime: 10 * (60 * 1000), // 10 mins
    //   cacheTime: 15 * (60 * 1000), // 15 mins
    // }
  );
};

export type ImageRessource = {
  url: string;
  alt: string;
  thumbnail: string;
  name: string;
  width: number;
  height: number;
}

export const useRessourceListQuery = (limit: number, page: number, subject: string) => {
  return useQuery(
    ["ressources", {limit, page, subject}],
    async (): Promise<[Ressource[], number]> => {
      const token = localStorage.getItem("token");
      console.log(subject, 'lelele')
      if(!subject) return [[], 0];
      const result = await axios.get(`https://bot.harvely.com/api/resources?course=${subject}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          limit,
          page,
        }
      });
      return result.data || {error: 'token'};
    },
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins,
    }
  );
};

export const useRessourceListMutation = (setText: any) => {
  const token = localStorage.getItem("token");
  const queryClient = useQueryClient();
  const GptForm = ({limit, page, subject, search}: any) => {
    localStorage.removeItem("situationInProgress");
    return axios.get(`https://bot.harvely.com/api/resources?course=${subject}&search=${search}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        limit,
        page,
      }
    });
  };

  return useMutation(GptForm, {
    onSuccess: (result: AxiosResponse) => {
      setText(result.data);
      return result.data;
    },

    onError: (error: AxiosError) => {
      makeToast(
        "Nous n'avons pas pu genérer votre situation, Veuillez réessayer  !",
        defaultOptions
      );
    },
  });
};