import React from 'react';
import { useUserProfile } from './hooks';
import { Layout } from '../../components/layout';
import { useFicheListQuery } from '../situations/full/hook';
import { FaPhoneVolume, FaRegCircleUser } from "react-icons/fa6";
import { MdOutlineMailOutline } from "react-icons/md";

const Profile: React.FC = () => {
  // const [page, setPage] = React.useState(1);
  // const { data } = useUserList(page);
  const { data: user } = useUserProfile();
  const activeSubscription = user?.subscriptionData?.subcriptions?.find(
    (sub: any) => sub.status === 'active'
  );
  const { data } = useFicheListQuery(5, 1);

  // Determine the remaining credits
  const remainingCredits = activeSubscription
    ? (30 - user?.subscriptionData?.thisMonthFiches)
    : user?.availableStandaloneMonthlySubscription;
    const endDate = activeSubscription?.endDate;
    const formattedEndDate = endDate ? new Date(endDate).toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }) : "N/A";

  return (
   <Layout activeMenu="profile">
        <div className="px-5 pt-2 sm:pt-4 flex md:flex-row flex-col md:gap-24 sm:gap-8 mb-8">
        <div className="grid md:grid-rows-2 grid-cols-1 sm:grid-cols-1 md:gap-16 sm:gap-8 mb-8 md:w-[40%] w-full">
          <div className="bg-[#CFE6E2]/40 p-12 rounded-[15px] h-fit flex flex-col items-center justify-center gap-2">
            <h2 className="text-primary50 text-[20px] font-semibold uppercase">
            {activeSubscription ? "ABONNEMENT" : "TOTAL CREDIT"}
            </h2>
            <span className='text-primary50 text-[48px] font-bold'>{remainingCredits}</span>
                <span className={`${activeSubscription ? "text-[#fff]" : ""} text-[18px] font-medium flex flex-col`}>
                  <span>Fiche(s) restante(s)</span>
                  <span>{activeSubscription ? `Valide jusqu'au ${formattedEndDate || "N/A"}` : ""}</span>
                </span>
          </div>
          
          <div className="bg-[#CFE6E2]/40 p-12 rounded-[15px] h-fit flex flex-col items-center justify-center gap-2">
            <h2 className="text-primary50 text-[20px] font-semibold uppercase">
              TOTAL FICHES GENERES
            </h2>
              <p className="text-primary50 text-[48px] font-bold">
              {data?.[1] || '0'}
              </p>
          </div>
           </div>
       <div className="bg-[#CFE6E2]/40 p-12 rounded-[15px] md:w-[60%] w-full flex flex-col gap-4">
       <h2 className="text-primary50 text-[20px] font-semibold uppercase">
            PROFILE
            </h2>
                    <div className='mt-8'>
                <div className='flex flex-row gap-8 mb-4'>
                    <FaRegCircleUser className='text-[20px]'/>
                      <div className='flex flex-col gap-2 mb-8 font-medium text-[16px]'>
                        <p>Nom</p>
                        <p className='text-[16px] text-[#0E9F90]'>{user?.firstName} {user?.lastName}</p>
                      </div>
                    </div>

                    <div className='flex flex-row gap-8 mb-4 overflow-x-auto'>
                    <MdOutlineMailOutline className='text-[20px]'/>
                      <div className='flex flex-col gap-2 mb-8 font-medium text-[16px]'>
                        <p className=''>Email</p>
                        <p className='text-[16px] text-[#0E9F90]'>{user?.email}</p>
                      </div>
                    </div>

                    <div className='flex flex-row gap-8 mb-8'>
                    <FaPhoneVolume  className='text-[20px]'/>
                      <div className='flex flex-col gap-2 mb-8 font-medium text-[16px]'>
                        <p>Telephone</p>
                        <p className='text-[16px] text-[#0E9F90]'>{user?.phoneNumber}</p>
                      </div>
                    </div>
        
                  
                    </div>
      </div>
        </div>
   </Layout>
  );
};

export default Profile;