import React, { useEffect } from "react";
import AuthRoutes from "../routes/auth";
import AppRoutes from "../routes/app";
import { UserContext } from "../context/user.context";
import { User } from "../features/users/types/user";
import { useUserProfile } from "./profile/hooks";
import { useLocation } from "react-router-dom";
import {GoogleOAuthProvider} from '@react-oauth/google'

const App: React.FC = () => {
  const token = localStorage.getItem("token");
  const location = useLocation();
  const course = new URLSearchParams(location.search).get("course");
  const lesson = new URLSearchParams(location.search).get("lesson");
  const previousLesson = new URLSearchParams(location.search).get("previousLesson");
  const areaOfLife = new URLSearchParams(location.search).get("areaOfLife");

  const selectedPayment = new URLSearchParams(location.search).get("paymentType");
  const selectedPaymentQuantity = new URLSearchParams(location.search).get("quantity");

  

  useEffect(() => {
    if (course && lesson && areaOfLife) {
      localStorage.setItem(
        "situationInProgress",
        JSON.stringify({ course, lesson, areaOfLife, previousLesson })
      );
    }
    if (selectedPayment && selectedPaymentQuantity) {
      localStorage.setItem("paymentData", JSON.stringify({
        paymentType: selectedPayment,
        tokenPurchased: selectedPayment === 'PAY_AS_YOU_GO' ? selectedPaymentQuantity : undefined,
        subscriptionLength: selectedPayment === 'SUBSCRIPTION' ? selectedPaymentQuantity : undefined,
      }));
    }
  }, []);

  const profile = useUserProfile(token || "", true);

  return (
    <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID || '1060554873119-2bt08s87rn1tje2dorpoj4uvp9hpg7kl.apps.googleusercontent.com'}>
      {!token ? (
        <AuthRoutes />
      ) : (
        <UserContext.Provider value={{ ...(profile.data || ({} as User)) }}>
          <AppRoutes />
        </UserContext.Provider>
      )}
    </GoogleOAuthProvider>
  );
};

export default App;
