import { useSituationList } from "../fetchdata";
import { useNavigate } from "react-router-dom";

const SituationList: React.FC<{ setSelectedSituation: any }> = (props) => {
  const situations = useSituationList();
  const navigate = useNavigate();

  return (
    <div className="h-[100vh]">
      <div className="mt-2 flex flex-col gap-10">
        {situations?.data?.map?.((situation: any, index: any) => (
          <div key={situation.id} className={`bg-[#EDE7E5] rounded-[15px] p-5`}>
            <div className="flex flex-row gap-5">
              <p className="bg-[#EFDBDB] px-4 py-1 rounded-[5px] uppercase font-medium text-[14px]">
                {situation?.course}
              </p>

              <p className="bg-[#FFE4CA] px-4 py-1 rounded-[5px] uppercase font-medium text-[14px]">
                {situation?.areaOfLife}
              </p>

              <button
                className="ml-auto bg-primary50 rounded-[5px] px-3 py-1 text-[12px] text-[#fff] cursor-pointer"
                onClick={() => {
                  navigate(`/situations/detail?ref=${situation.id}`);
                }}
              >
                VOIR
              </button>
            </div>
            <p className="text-primary-900 font-semibold text-[14px] text-left my-4 overflow-x-hidden">
              {situation.lessonTitle}
            </p>
            <p className="max-h-[50px]  text-ellipsis overflow-y-hidden ">
              {situation?.situation}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SituationList;
