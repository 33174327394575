export const FINISHED_VERIFYING_AUTHENTICATION =
  'FINISHED_VERIFYING_AUTHENTICATION';
export const AUTHENTICATION_STATE = 'AUTHENTICATION_STATE';
export const FINISHED_UPLOAD_IMAGES = 'FINISHED_UPLOAD_IMAGES';
export const INITIAL_DATA_LOAD_STATE = 'INITIAL_DATA_LOAD_STATE';
export const USER_DATA_LOAD_STATE = 'USER_DATA_LOAD_STATE';
export const USER_AUTH_SIGN_METHOD_STATE = 'USER_AUTH_SIGN_METHOD_STATE';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const SET_NETWORK_CONNECTION_STATE = 'SET_NETWORK_CONNECTION_STATE';
export const SET_TOKEN = 'SET_TOKEN';
export const PAYMENT_DATA = 'PAYMENT_DATA';
export const SIGNUP_DATA = 'SIGNUP_DATA';

export const finishedUploadImages = () => {
  return {type: FINISHED_UPLOAD_IMAGES};
};

export const finishedVerifyingAuthentication = () => {
  return {type: FINISHED_VERIFYING_AUTHENTICATION};
};

export const setAuthenticationState = (isAuthenticated: boolean) => {
  return {type: AUTHENTICATION_STATE, payload: isAuthenticated};
};

export const setLoadInitialDataState = (isLoaded: boolean) => {
  return {type: INITIAL_DATA_LOAD_STATE, payload: isLoaded};
};

export const setLoadUserDataState = (isLoaded: boolean) => {
  return {type: USER_DATA_LOAD_STATE, payload: isLoaded};
};

export const setAuthenticationSignMethodState = (isSignedUp: boolean) => {
  return {type: USER_AUTH_SIGN_METHOD_STATE, payload: isSignedUp};
};

export const logOut = () => {
  return {type: USER_LOGGED_OUT};
};

export const setOnlineStatus = (isOnline: boolean) => {
  return {
    type: SET_NETWORK_CONNECTION_STATE,
    payload: isOnline,
  };
};

export const setToken = (token: string) => {
  return {
    type: SET_TOKEN,
    payload: token,
  };
};

export const setLoginData = (data: any) => {
  return {
    type: 'LOGIN_SUCCESS',
    payload: data,
  };
};

export const setSignupData = (data: any) => {
  return {
    type: 'SIGNUP_DATA',
    payload: data,
  };
};

export const setSignupData2 = (data: any) => {
  return {
    type: SIGNUP_DATA,
    payload: data,
  };
};

export const setPaymentData = (data: any) => {
  return {
    type: PAYMENT_DATA,
    payload: data,
  };
};
