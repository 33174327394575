import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { postApi } from '../../../../utils/api';
import makeToast, { defaultOptions } from '../../../../utils/toast';

export const useSignup = (navigate: any, setError: any) => {
  const signup = (data: any) => {
    return postApi('v1/auth/email/register', data);
  };
  return useMutation(signup, {
    onSuccess: async (result: AxiosResponse) => {
      if (result.status === 200 || result.status === 201) {
        makeToast(
          'Inscription réussie',
          defaultOptions,
        );
        localStorage.setItem('token', result?.data?.token);
        navigate('/')
        window.location.reload();
      }
    },
    onError: (error: any) => {
      let message = 'Please check your network';
      if (error?.response?.data?.errors?.email) {
        message = 'Email already exists';
        setError('email');
      } else if (error?.response?.data?.errors?.phoneNumber) {
        message = 'Username already exists';
        setError('phoneNumber');
      }
      //console.log(error.response?.data);
    },
  });
};