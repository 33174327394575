import axios from "axios";

const backendUrl = "https://bot.harvely.com";

const createApi = (
  url: string
): {
  [key: string]: (id?: string) => Promise<any>;
} => {
  return new Proxy(
    {},
    {
      get(target, key: string) {
        return async function (id = "") {
          try {
            const response = await fetch(`${url}/${key}/${id}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            });
            if (response.ok) {
              return response.json();
            }
            throw { status: response.status };
          } catch (error: any) {
            if (error.status) throw error;
            else throw new Error("network");
          }
        };
      },
    }
  );
};

export const api = createApi(`${backendUrl}/api`);

export const postApi = async (url: string, body: any) => {
  return axios.post(`${backendUrl}/api/${url}`, body, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "application/json",
    },
  });
};

export const patchApi = async (url: string, body: any) => {
  return axios.patch(`${backendUrl}/api/${url}`, body, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "application/json",
    },
  });
};

export const deleteApi = async (url: string) => {
  return axios.delete(`${backendUrl}/api/${url}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "application/json",
    },
  });
};
