import { FC, ReactNode } from "react";
import { BiX } from "react-icons/bi";

interface SidePannelProps {
  children: ReactNode;
  style?: string;
  sidePannelTitle?: string;
  sidePannelOnclose?: () => void;
}
export const SidePannel: FC<SidePannelProps> = (props) => {
  return (
    <div className="fixed bg-primary50 bg-opacity-60 w-full h-full z-[999999]">
    <div
      className={`fixed shadow-lg z-[200] right-0 top-0 h-screen overflow-y-scroll no-scrollbar bg-secondary50 p-6 md:p-10 lg:p-16 ${props.style}`}
    >
      <div className="mb-10 flex flex-row justify-between">
        <div>
          <h1 className="font-bold text-lg">{props.sidePannelTitle}</h1>
          <div className="h-[2px] w-[40px] mt-[5px] bg-primary50" />
        </div>
        <BiX
          size={30}
          cursor="pointer"
          color="text-primary50"
          className="mt-[-20px]"
          onClick={() => {
            props.sidePannelOnclose?.();
          }}
        />
      </div>
      {props.children}
    </div>
    </div>
  );
};
