import Input from "../../components/input"

const PrevisionForm = () => {
    return (
        <form>
            <Input placeholder={""} name={""} />
        </form>
    )
}

export default PrevisionForm;