import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type Lesson = {
  id: number;
  title: string;
  subject: string;
};

export type Situation = {
  id: string;
  situation: string;
  createdAt: string;
  lessonTitle: string;
  course: string;
  areaOfLife: string;
  exercice: string;
  activity: string;
};

export const useLessonList = (tokens: string) => {
  return useQuery(
    ["lessons"],
    async (): Promise<Lesson[]> => {
      const token = localStorage.getItem("token");
      const result = await axios.get("https://bot.harvely.com/api/lesson", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return result.data || [];
    },
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
    }
  );
};

export const useSituationList = () => {
  return useQuery(
    ["situations"],
    async (): Promise<Situation[]> => {
      const token = localStorage.getItem("token");
      const result = await axios.get("https://bot.harvely.com/api/teacher", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return result.data || [];
    },
    {
      keepPreviousData: true,
      cacheTime: 86400000,
      staleTime: 1000 * 60 * 60 * 24,
    }
  );
};
