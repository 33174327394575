import React from 'react';
import Pagination from '../../components/pagination';
import { Layout } from '../../components/layout';
import { teachers } from '../../utils/teachers';
import { IoEye } from "react-icons/io5";

const TeachersList: React.FC = () => {
  // const [page, setPage] = React.useState(1);
  // const { data } = useUserList(page);

  return (
    <Layout activeMenu="Accueil">
        <div className="my-8 bg-[#FFFFFF] rounded-[10px]">
      {/* <div className="md:hidden flex flex-col rounded-[15px] overflow-y-scroll w-full py-5 mb-[150px]">
        {Array.isArray(data) &&
          data?.[0]?.map?.((user: any) => (
            <div
              className="shadow-md shadow-primary-300 bg-primary-200 rounded-[15px] p-3 min-w-[100%] mb-5"
              key={`${user.id}-update`}
            >
              <div className={`flex flex-row `}>
                <div className="flex flex-grow flex-col">
                  <div className="flex flex-row justify-between w-full">
                    <p className="text-[14px] text-blue font-bold mt-[1px]">
                      {user.username}
                    </p>
                  </div>
                  <div className="flex flex-row justify-between w-full mt-0">
                    <p className="text-[14px] text-blue font-medium mt-[1px]">{user.email}</p>
                  </div>
                  <div className="flex flex-row justify-between w-full mt-3">
                    <p className="text-[14px] text-blue font-medium mt-[1px]">{user.phoneNumber}</p>
                  </div>
                  <div className="flex flex-row justify-between w-full mt-0"></div>
                </div>
              </div>
              <div className="w-full h-[1px] bg-[#000] bg-opacity-10 mt-5" />
            </div>
          ))}
      </div> */}
      <table className="table-fixed w-full p-5 sm:hidden">
        <thead className="mx-5 text-[#000] w-full">
          <tr className="rounded-[20px] w-full">
            <th className=" rounded-tl-[10px] text-[#FFF] font-semibold text-left pl-5 py-4 bg-[#0E9F90] w-[10%]">
              ID
            </th>
            <th className="text-[#FFF] font-semibold text-left pl-5 py-4 bg-[#0E9F90] w-[50%]">
              NOM COMPLET
            </th>
            <th className="text-[#FFF] font-semibold text-left pl-5 py-4 bg-[#0E9F90]">
              TELEPHONE
            </th>
            <th className="rounded-tr-[10px] text-[#FFF] font-semibold text-left pl-5 py-4 bg-[#0E9F90]">
              ACTIONS
            </th>
          </tr>
        </thead>
          <tbody>
            {teachers?.map?.((teacher: any, index: any) => (
              <tr
                key={index}
                className="my-1"
              >
                <td className="text-primary-900 font-semibold text-md text-left pl-5 py-4">
                  {index + 1}
                </td>
                <td className="text-primary-900 font-semibold text-md text-left pl-5 py-4">
                  {teacher.firstName} {teacher.lastName}
                </td>
                <td className="text-primary-900 font-normal text-md text-left pl-5 py-4 ">
                  {teacher.telephone}
                </td>
                <td className="flex flex-row text-primary-900 w-[250px] font-normal text-md text-left pl-5 py-4 ">
                  <IoEye
                    fontSize={20}
                    height={20}
                    style={{ marginRight: 15, cursor: 'pointer' }}
                    onClick={() => {
                      // client.setQueryData(
                      //   ['patients-single', { userId: user.id }],
                      //   user,
                      // );
                    //   setUserDetail(user);
                    }}
                  />
                </td>
              </tr>
             ))}
          </tbody>
      </table>
      <div className="sm:fixed flex  sm:bottom-0 w-full z-50  sm:bg-white">
        {/* <Pagination
          pagesCount={Math.floor(data?.[1] || 0) / 5}
          currentPage={1}
          onPageChange={(page: any) => {
            setPage(page.selected + 1);
          }}
        /> */}
      </div>
    </div>
    </Layout>
  );
};

export default TeachersList;