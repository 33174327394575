import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { postApi } from '../../../../utils/api';
import makeToast, { defaultOptions } from '../../../../utils/toast';

export const useResetPassword = (navigate: any) => {
  const reset = (data: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return postApi(`v1/auth/reset/password?token=${params.token}`, {
      ...data,
      hash: params.hash,
    });
  };
  return useMutation(reset, {
    onSuccess: (res) => {
      makeToast('Votre mot de passe a été réinitialisé, veuillez vous connecter.', defaultOptions);
      navigate('/login');
    },
    onError: (error: AxiosError) => {
      // //console.log(error);
      makeToast('Une erreur est survenue, veuillez réessayer.', {
        type: 'error',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    },
  });
};