import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import makeToast, { defaultOptions } from "../../utils/toast";

const baseUrl = "https://bot.harvely.com/api/payments";

export const usePaymentForm = (setData: any, tokens = "") => {
  const queryClient = useQueryClient();
  const paymentForm = (data: any) => {
    const token = localStorage.getItem("token");
    return axios.post(
      baseUrl + '/flexpay',
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  return useMutation(paymentForm, {
    onSuccess: (result: AxiosResponse) => {
      setData(result.data);
      queryClient.invalidateQueries(["user-profile"]);
    },

    onError: (error: AxiosError) => {
      makeToast(
        "Nous n'avons pas pu traiter votre paiement, Veuillez réessayer ou contacter le service client  !",
        defaultOptions
      );
    },
  });
};

export const usePaymentFormRetry = (setData: any, tokens = "") => {
  const queryClient = useQueryClient();
  const paymentForm = (data: any) => {
    const token = localStorage.getItem("token");
    return axios.post(
      `${baseUrl}/${data.id}/retry`,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  return useMutation(paymentForm, {
    onSuccess: (result: AxiosResponse) => {
      setData(result.data);
      makeToast("Veuillez vérifier votre téléphone pour insérer le code de confirmation.", defaultOptions);
    },

    onError: (error: AxiosError) => {
      makeToast(
        "Nous n'avons pas pu traiter votre paiement, Veuillez réessayer ou contacter le service client  !",
        defaultOptions
      );
    },
  });
};

export const usePaymentFormClaim = (setData: any, tokens = "") => {
  const queryClient = useQueryClient();
  const paymentForm = (data: any) => {
    const token = localStorage.getItem("token");
    return axios.post(
      `${baseUrl}/${data.id}/claim`,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  return useMutation(paymentForm, {
    onSuccess: (result: AxiosResponse) => {
      setData(result.data);
      makeToast(
        "Nos équipes ont été notifiées de votre demande, nous vous contacterons dans les plus brefs délais !",
        defaultOptions
      );
    },

    onError: (error: AxiosError) => {
      makeToast(
        "Nous n'avons pas pu traiter votre paiement, Veuillez réessayer ou contacter le service client  !",
        defaultOptions
      );
    },
  });
}
