import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { QueryClient, QueryErrorResetBoundary } from '@tanstack/react-query';
import './i18n';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { ErrorBoundary } from 'react-error-boundary';
import makeToast, { defaultOptions } from './utils/toast';
import { logout } from './utils/auth';
import { ErrorLoading, Loading } from './components/loader/page';
import App from './pages/App';
import { Provider } from 'react-redux';
import store from './redux/store';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      networkMode: 'offlineFirst',
      useErrorBoundary: true,
      keepPreviousData: true,
      onError: (error: any) => {
        if (error.status === 401) {
          makeToast('Session Expirée', defaultOptions);
          logout(queryClient);
        }
      },
    },
    mutations: {
      networkMode: 'offlineFirst',
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

let root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
  <Provider store={store}>
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            FallbackComponent={({ resetErrorBoundary }) => (
              <ErrorLoading reset={resetErrorBoundary} />
            )}
            onReset={reset}
          >
            <Suspense fallback={<Loading />}>
              <App />
            </Suspense>
            <ToastContainer />
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </PersistQueryClientProvider>
    </Provider>
  </BrowserRouter>,
);

serviceWorkerRegistration.register();