import { useLocation, useNavigate } from "react-router-dom";
import { useUserProfile } from "../profile/hooks";
import { useEffect, useState } from "react";
import CreateSituationPage from "../situations";
import { Layout } from "../../components/layout";
import { Situation } from "../situations/fetchdata";
import { BsPlusSquare } from "react-icons/bs";
import { LoadingIcon } from "../../components/loader";
import { useGptForm } from "../situations/mutation";
import { useFicheListQuery } from "../situations/full/hook";
import { PricingV2 } from "../payment/price";
import makeToast, { defaultOptions } from "../../utils/toast";
import { ToastContainer } from "react-toastify";

const Home: React.FC = () => {
  const { data: user } = useUserProfile();
  const navigate = useNavigate();
  const [showCreateSituation, setShowCreateSituation] =
    useState<boolean>(false);
  const [showTransactionForm, setShowTransactionForm] =
    useState<boolean>(false);
  const [selectedSituation, setSelectedSituation] = useState<Situation>();
  let sidePannelView = null;
  let sidePannelStyle = "";
  let sidePannelTitle = "";
  let closeSidePannel = () => {
    setShowCreateSituation(false);
    setShowTransactionForm(false);
    setSelectedSituation(undefined);
    localStorage.removeItem("situationInProgress");
    localStorage.removeItem("paymentData");
  };

  const [paymentData, setPaymentData] = useState<any>();

  if (showCreateSituation || selectedSituation) {
    sidePannelView = (
      <CreateSituationPage
        result={selectedSituation?.situation}
        title={'Creer une fiche'}
        isFiche
      />
    );
    sidePannelStyle = "";
    sidePannelTitle = selectedSituation
      ? "Fiche enregistrée"
      : "Creer une nouvelle fiche";
  }

  if (showTransactionForm) {
    sidePannelView = <PricingV2 paymentData={paymentData} />;
    sidePannelStyle = "";
    sidePannelTitle = "Recharger les fiches";
  }

  const location = useLocation();
  const course = new URLSearchParams(location.search).get("course");
  const lesson = new URLSearchParams(location.search).get("lesson");
  const areaOfLife = new URLSearchParams(location.search).get("areaOfLife");
  const previousLesson = new URLSearchParams(location.search).get("previousLesson");

  const selectedPayment = new URLSearchParams(location.search).get("paymentType");
  const selectedPaymentQuantity = new URLSearchParams(location.search).get("quantity");

  const mutation = useGptForm((_s: string, id: string) => {
    navigate(`/situations/detail?ref=${id}`);
  });

  useEffect(() => {
    const situationInProgress = localStorage.getItem("situationInProgress");
    const paymentInProgress = localStorage.getItem("paymentData");

    let payment: any = {};
    if (paymentInProgress) {
      payment = JSON.parse(paymentInProgress);
      setPaymentData(payment);
      setShowTransactionForm(true);
    } else if (selectedPayment && selectedPaymentQuantity) {
      payment = {
        paymentType: selectedPayment,
        tokenPurchased: selectedPayment === 'PAY_AS_YOU_GO' ? selectedPaymentQuantity : undefined,
        subscriptionLength: selectedPayment === 'SUBSCRIPTION' ? selectedPaymentQuantity : undefined,
      };
    

      setPaymentData(payment);
    }

    let situation: any = {};
    if (situationInProgress) {
      situation = JSON.parse(situationInProgress);
    } else if (course && lesson && areaOfLife && previousLesson) {
      situation = {
        course,
        lesson,
        areaOfLife,
        previousLesson,
      };
    }

    if (situation.course) {
      mutation.mutate({
        lessonTitle: situation.lesson,
        subject: situation.course,
        areaOfLife: situation.areaOfLife,
        previousLesson: situation.previousLesson,
        class: "5",
        level: "primaire",
        isFiche: true,
      });
    }
  }, []);

  const { data } = useFicheListQuery(5, 1);
  
  // Extract subscription information
  const activeSubscription = user?.subscriptionData?.subcriptions?.find(
    (sub: any) => sub.status === 'active'
  );

  // Determine the remaining credits
  const remainingCredits = activeSubscription
    ? (30 - user?.subscriptionData?.thisMonthFiches)
    : user?.availableStandaloneMonthlySubscription;

    const endDate = activeSubscription?.endDate;
    const formattedEndDate = endDate ? new Date(endDate).toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }) : "N/A";


  return (
    <Layout
      activeMenu="Acceuil"
      sidePannelView={sidePannelView}
      sidePannelOnclose={closeSidePannel}
      sidePannelStyle={sidePannelStyle}
      sidePannelTitle={sidePannelTitle}
    >
      {mutation.isLoading && (
        <div className="absolute h-full w-full top-0 left-0 bg-primary50/50 flex flex-row justify-center items-center">
          <div className=" md:w-[35%] ">
            <div className=" p-5 rounded-[10px] bg-secondary50 bg-opacity-95  w-full h-full z-50 text-lg text-center flex justify-center items-center flex-col">
              <p className="font-semibold text-[14px]">
                Veuillez patienter, nous traitons votre demande...
              </p>
              <div className="ml-[10px] mt-5">
                <LoadingIcon height={24} />{" "}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className=" px-5 pt-2 sm:pt-4">
        <div className="grid grid-cols-2 sm:grid-cols-1 md:gap-16 sm:gap-8 mb-8">
          <div className="bg-[#CFE6E2] p-4 rounded-[15px] h-fit">
            <h2 className="text-primary50 text-[14px] font-medium uppercase">
              TOTAL FICHES GENERES
            </h2>
            <div className="flex flex-row items-center justify-between mt-3 ">
              <p className="text-primary50 text-[2rem] font-bold">
                {data?.[1] || '0'}
              </p>
                <button
                  className="h-[30px] px-4 text-[14px] bg-[#0E9F90]  rounded-[5px] text-[#fff] uppercase"
                  onClick={() => {
                    if((remainingCredits || 0) <= 0) {
                      makeToast("Vous n'avez plus de crédit disponible pour créer une fiche", {
                        ...defaultOptions,
                        type: 'error',
                      });
                      setShowTransactionForm(true);
                    }
                    setShowCreateSituation(true);
                  }}
                >
                  CREER
                </button>
            </div>
          </div>

          <div className={`${activeSubscription ? "bg-[#0E9F90]" : "bg-[#CFE6E2] border-[0px]"} p-4 rounded-[15px] h-fit`}>
            <h2 className={`${activeSubscription ? "text-[#fff]" : ""} text-[14px] font-medium uppercase`}>
            {activeSubscription ? "ABONNEMENT" : "TOTAL CREDIT"}
            </h2>
            <div className="flex flex-row items-center justify-between mt-3">
              <p className={`${activeSubscription ? "text-[#fff]" : ""} text-[2rem] font-bold flex flex-row gap-4 items-start`}>
                <span>{remainingCredits}</span>
                <span className={`${activeSubscription ? "text-[#fff]" : ""} text-[14px] font-medium flex flex-col`}>
                  <span>Fiche(s) restante(s)</span>
                  <span>{activeSubscription ? `Valide jusqu'au ${formattedEndDate || "N/A"}` : ""}</span>
                </span>
              </p>

              <button
                className={`${activeSubscription ? "text-[#0E9F90] bg-[#fff]" : "bg-[#0E9F90] text-[#fff]"} h-[30px] px-4 text-[14px]  rounded-[5px] uppercase`}
                onClick={() => {
                  setShowTransactionForm(true);
                }}
              >
                RECHARGER
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row  items-center gap-6 md:mt-15 sm:mt-8 px-5">
        <h2 className="text-primary50/80 text-[16px] sm:w-full font-bold uppercase ">
          Fiches recentes
        </h2>
        {(remainingCredits || 0) > 0 && <BsPlusSquare
          onClick={() => {
            if((remainingCredits || 0) <= 0) {
              makeToast("Vous n'avez plus de crédit disponible pour créer une fiche", {
                ...defaultOptions,
                type: 'error',
              });
              setShowTransactionForm(true);
            }
            setShowCreateSituation(true);
          }}
          className="cursor-pointer"
        />}
      </div>
      <div className="px-5 mt-10">
      <div className="flex flex-col gap-5 mt-10  p-0 md:p-0">

        {Array.isArray(data?.[0]) && data?.[0]?.map(situation => <div key={situation.id} className={`bg-[#EDE7E5] rounded-[15px] p-5`}>
        <div className="flex flex-row gap-5">
            <p className="bg-[#EFDBDB]  px-4 py-1 rounded-[5px] uppercase font-medium text-[14px] h-[30px] overflow-y-hidden">
                {situation?.subject}
            </p>

            <p className="bg-[#868686] px-4 py-1 rounded-[5px] uppercase font-medium text-[14px]">
                {situation?.areaOfLife}
            </p>

            <button
                className="ml-auto bg-primary50 rounded-[5px] px-3 py-1 text-[12px] text-[#fff] cursor-pointer"
                onClick={() => {
                    navigate(`/fiches/${situation.id}`);
                }}
            >
                VOIR
            </button>
        </div>
        <p className="text-primary-900 font-semibold text-[14px] text-left my-4 overflow-x-hidden">
        {situation.lessonTitle}
        </p>
        <p className="max-h-[50px]  text-ellipsis overflow-y-hidden ">
        {situation?.objectives}
        </p>
        </div>)}
      </div>
      </div>
    </Layout>
  );
};

export default Home;
