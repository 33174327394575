import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from '../pages/auth/login';
import Signup from '../pages/auth/signup';
import ForgetPassword from '../pages/auth/forget';
import ResetPassword from '../pages/auth/reset';
import { CreateFiche } from '../pages/situations/full/create-fiche';

const AuthRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<WelcomePage />} /> */}
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forget" element={<ForgetPassword />} />
      <Route path="/reset" element={<ResetPassword />} />
      <Route path="*" element={<Navigate to="/login" />} />
      <Route path="/fiches/:id" element={<CreateFiche />} />
    </Routes>
  );
};

export default AuthRoutes;