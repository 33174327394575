export const prices = [
    {
      id: 1,
      fiche: '30',
      price: '$5',
      frequency: '/mois',
      selectedLength: 1,
      name: 'Abonnement mensuel',
      list: [
        "<b>30 fiches</b> conformes aux programmes utilisant l'APS/APC",
        "Au-delà de 30 fiches, <b>0,2 $</b> par fiche supplémentaire",
        "Invitation à nos formations trimestrielles sur <b>l'APS/APC</b> et la <b>bureautique</b>",
        "Support technique disponible",
      ],
    },
    {
      id: 2,
      fiche: '30',
      price: '$4.5',
      frequency: '/mois',
      selectedLength: 3,
      name: 'Abonnement trimestriel',
      list: [
        "<b>30 fiches</b> conformes aux programmes utilisant l'APS/APC",
        "Au-delà de 30 fiches, <b>0,15 $</b> par fiche supplémentaire",
        "Invitation à nos formations trimestrielles sur <b>l'APS/APC</b> et la <b>bureautique</b>",
        "Support technique disponible",
        "Impression des fiches gratuitement dans nos locaux ou chez les partenaires",
      ],
    },
  ];
  