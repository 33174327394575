import React, { useState, useEffect } from "react";
import { Layout } from "../../components/layout";
import { usePaymentList } from "./fetch";
import { PricingV2 } from "./price";
import { IoIosRefresh, IoMdCloseCircleOutline } from "react-icons/io";
import { FiInbox, FiFileText, FiCheckCircle } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserProfile } from "../profile/hooks";
import { useGptForm } from "../situations/mutation";
import { pdf} from "@react-pdf/renderer";
import saveAs from "file-saver";
import Invoice from "./invoice";
import { useFicheListQuery } from "../situations/full/hook";
import { usePaymentFormClaim, usePaymentFormRetry } from "./mutation";
import { LoadingIcon } from "../../components/loader";


const PaymentList: React.FC = () => {
  const { data: payments } = usePaymentList("");
  const [showTransactionForm, setShowTransactionForm] = useState<boolean>(false);
  let sidePannelView = null;
  let sidePannelStyle = "";
  let sidePannelTitle = "";
  let closeSidePannel = () => {
    setShowTransactionForm(false);
  };

  if (showTransactionForm) {
    sidePannelView = <PricingV2 />;
    sidePannelStyle = "";
    sidePannelTitle = "Paiement";
  }

  const [selectPaymentId, setSelectPaymentId] = useState<string | null>(null);

  const mutationRetry = usePaymentFormRetry((data: any) => {
    setSelectPaymentId(null)
  });

  const mutationClaim = usePaymentFormClaim((data: any) => {
    setSelectPaymentId(null)
  });

  const { data: user } = useUserProfile();
  const navigate = useNavigate();

  const location = useLocation();
  const course = new URLSearchParams(location.search).get("course");
  const lesson = new URLSearchParams(location.search).get("lesson");
  const areaOfLife = new URLSearchParams(location.search).get("areaOfLife");
  const previousLesson = new URLSearchParams(location.search).get("previousLesson");

  const mutation = useGptForm((_s: string, id: string) => {
    navigate(`/situations/detail?ref=${id}`);
  });

  useEffect(() => {
    const situationInProgress = localStorage.getItem("situationInProgress");

    let situation: any = {};
    if (situationInProgress) {
      situation = JSON.parse(situationInProgress);
    } else if (course && lesson && areaOfLife && previousLesson) {
      situation = {
        course,
        lesson,
        areaOfLife,
        previousLesson,
      };
    }

    if (situation.course) {
      mutation.mutate({
        lessonTitle: situation.lesson,
        subject: situation.course,
        areaOfLife: situation.areaOfLife,
        previousLesson: situation.previousLesson,
        class: "5",
        level: "primaire",
        isFiche: true,
      });
    }
  }, []);
  
  // Extract subscription information
  const activeSubscription = user?.subscriptionData?.subcriptions?.find(
    (sub: any) => sub.status === 'active'
  );

  // Determine the remaining credits
  const remainingCredits = activeSubscription
    ? (30 - user?.subscriptionData?.thisMonthFiches)
    : user?.availableStandaloneMonthlySubscription;

    const endDate = activeSubscription?.endDate;
    const formattedEndDate = endDate ? new Date(endDate).toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }) : "N/A";

    const handleDownloadInvoice = async (payment: any) => {
      const blob = await pdf(<Invoice payment={payment} />).toBlob();
      saveAs(blob, `invoice_${payment.id}.pdf`);
    };

  return (
    <Layout
      activeMenu="Paiements"
      sidePannelView={sidePannelView}
      sidePannelOnclose={closeSidePannel}
      sidePannelStyle={sidePannelStyle}
      sidePannelTitle={sidePannelTitle}
    >
      <div className=" px-5 pt-2 sm:pt-4">
      <h2 className="text-primary50 font-bold text-lg mb-8">Paiements</h2>
        <div className="grid grid-cols-2 sm:grid-cols-1 md:gap-16 sm:gap-8 mb-8">
          <div className="bg-[#CFE6E2] p-4 rounded-[15px] h-fit">
            <h2 className="text-primary50 text-[14px] font-medium uppercase">
             Total credits non expirable restants
            </h2>
            <div className="flex flex-row items-center justify-between mt-3 ">
              <p className="text-primary50 text-[2rem] font-bold">
              {user?.availableStandaloneMonthlySubscription}
              </p>
                <button
                  className="h-[30px] px-4 text-[14px] bg-[#0E9F90]  rounded-[5px] text-[#fff] uppercase"
                  onClick={() => {
                    setShowTransactionForm(true);
                  }}
                >
                  Payer
                </button>
            </div>
          </div>

          <div className={`${activeSubscription ? "bg-[#0E9F90]" : "bg-[#CFE6E2] border-[0px]"} p-4 rounded-[15px] h-fit`}>
            <h2 className={`${activeSubscription ? "text-[#fff]" : ""} text-[14px] font-medium uppercase`}>
            {activeSubscription ? "ABONNEMENT" : "TOTAL CREDIT"}
            </h2>
            <div className="flex flex-row items-center justify-between mt-3">
              <p className={`${activeSubscription ? "text-[#fff]" : ""} text-[2rem] font-bold flex flex-row gap-4 items-start`}>
                <span>{remainingCredits}</span>
                <span className={`${activeSubscription ? "text-[#fff]" : ""} text-[14px] font-medium flex flex-col`}>
                  <span>Fiches restants</span>
                  <span>{activeSubscription ? `Valide jusqu'au ${formattedEndDate || "N/A"}` : `TOTAL CREDIT`}</span>
                </span>
              </p>

              <button
                className={`${activeSubscription ? "text-[#0E9F90] bg-[#fff]" : "bg-[#0E9F90] text-[#fff]"} h-[30px] px-4 text-[14px]  rounded-[5px] uppercase`}
                onClick={() => {
                  setShowTransactionForm(true);
                }}
              >
                Payer
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4 md:hidden">
         {payments?.map?.((payment) => (
          <div key={payment.id}
          className={`${
            payment.status === "pending"
              ? "bg-[#FFF3E1]"
              : payment.status === "failed"
              ? "bg-[#9F0E31]/20"
              : "bg-[#0E9F90]/20"
          } flex flex-col gap-6 p-6 rounded-[15px]`}>
              <p> {payment.metadata.paymentType === "SUBSCRIPTION" ? `Abonnement ${payment.metadata.subscriptionLength} mois à partir du ${formattedEndDate || "N/A"}` : `Achat ${user?.availableStandaloneMonthlySubscription} fiches non expirables`}</p>
              <div className="flex flex-row items-center justify-between">
                <p>{formattedEndDate || "N/A"}</p>
                {payment.status === "pending" && 
                <p className="text-[#e86f0c] px-2 py-1 rounded-md w-[40%] text-center">En attente</p>}
                {payment.status === "success" && (
                  <p className="flex flex-row items-center gap-2">
                  <FiCheckCircle className="text-[16px] text-[#0E9F90]"/>
                    Reussi
                  </p>
                )}
                {payment.status === "failed" && (
                  <button className="flex flex-row items-center gap-2">
                  <IoMdCloseCircleOutline className="text-[16px] text-[#9F0E31]"/>
                   Echoué
                  </button>
                )}
              </div>
              {payment.status === "pending" && 
                <p className="bg-[#e86f0c]/20 text-[#e86f0c] px-2 py-1 rounded-md w-[40%] text-center">En attente</p>}
                {payment.status === "failed" && (
                  <div className="flex flex-row items-center gap-4 w-full">
                    <button onClick={() => {
                      mutationRetry.mutate({id: payment.id});
                      setSelectPaymentId(payment.id.toString());
                    }} className="bg-[#9F0E31] text-[#fff] px-2 py-1 rounded-md flex flex-row items-center justify-center gap-2 w-[50%]">
                    <IoIosRefresh className="text-[20px]"/>
                      Reessayer
                      {mutationRetry.isLoading && selectPaymentId === payment.id.toString() ? <LoadingIcon height={16} /> : null}
                    </button>
                    <button onClick={() => {
                      mutationClaim.mutate({id: payment.id});
                      setSelectPaymentId(payment.id.toString());
                    }} className="border-[2px] border-[#9F0E31] w-[50%] text-[#9F0E31] px-2 py-1 rounded-md flex flex-row items-center justify-center gap-2">
                    <FiInbox className="text-[20px]"/>
                      Reclamer
                      {mutationClaim.isLoading && selectPaymentId === payment.id.toString() ? <LoadingIcon height={16} /> : null}
                    </button>
                  </div>
                )}
                {payment.status === "success" && (
                  <button 
                  className="border-[2px] border-[#0E9F90] px-2 py-1 rounded-md flex flex-row items-center justify-center gap-2"
                  >
                  <FiFileText className="text-[20px] text-[#0E9F90]"/>
                    Telecharger le recu
                  </button>
                )}
          </div>
         ))}
        </div>

      <div className="hidden md:block w-[100%]">
      <table className="max-h-[60%] overflow-auto mt-6 w-full">
        <thead className="mx-5 text-blue w-full bg-yellow">
          <tr className="w-full">
            <th className="text-[14px] text-primary50 font-semibold text-left pl-5 py-4">
              Date
            </th>
            <th className="text-[14px] text-primary50 font-semibold text-left pl-5 py-4">
              Libellé
            </th>
            <th className="text-[14px] text-primary50 font-semibold text-left pl-5 py-4">
              Etat
            </th>
            <th className="text-[14px] text-primary50 font-semibold text-left pl-5 py-4">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {payments?.map?.((payment) => (
            <tr
              key={payment.id}
              className={`${
                payment.status === "pending"
                  ? "bg-[#FFF3E1]"
                  : payment.status === "failed"
                  ? "bg-[#9F0E31]/20"
                  : "bg-[#0E9F90]/20"
              } border-t-[16px] border-[#fff]`}
            >
              <td className="text-primary-900 font-semibold text-sm text-left pl-5 py-4 overflow-x-hidden">
                {new Date(payment.createdAt)?.toLocaleDateString('fr-FR')}
              </td>
              <td className="text-primary-900 font-semibold text-sm text-left pl-5 py-4 overflow-x-hidden">
                {payment.metadata.paymentType === "SUBSCRIPTION" ? `Abonnement ${payment.metadata.subscriptionLength} mois à partir du ${formattedEndDate || "N/A"}` : `Achat ${user?.availableStandaloneMonthlySubscription} fiches non expirables`}
              </td>
              <td className="text-primary-900 font-semibold text-sm text-left pl-5 py-4 overflow-x-hidden">
                {payment.status === "pending" && 
                <p className="text-[#e86f0c]">En attente</p>}
                {payment.status === "success" && (
                  <p className="flex flex-row items-center gap-2">
                  <FiCheckCircle className="text-[16px] text-[#0E9F90]"/>
                    Reussi
                  </p>
                )}
                {payment.status === "failed" && (
                  <p className="flex flex-row items-center gap-2">
                  <IoMdCloseCircleOutline className="text-[16px] text-[#9F0E31]"/>
                   Echoué
                  </p>
                )}
              </td>
              <td className="text-primary-900 font-semibold text-sm text-left pl-5 py-4 overflow-x-hidden">
                {payment.status === "pending" && 
                <p className="bg-[#e86f0c]/20 text-[#e86f0c] px-2 py-1 rounded-md w-[40%] text-center">En attente</p>}
                {payment.status === "failed" && (
                  <div className="flex flex-row items-center gap-6">
                    <button 
                    className="bg-[#9F0E31] text-[#fff] px-4 py-1 rounded-md flex flex-row items-center gap-2"
                    onClick={() => {
                      mutationRetry.mutate({id: payment.id});
                      setSelectPaymentId(payment.id.toString());
                    }} >
                    <IoIosRefresh className="text-[20px]"/>
                      Reessayer
                      {mutationRetry.isLoading && selectPaymentId === payment.id.toString() ? <LoadingIcon height={16} /> : null}
                    </button>
                    <button 
                    className="border-[2px] border-[#9F0E31] text-[#9F0E31] px-4 py-1 rounded-md flex flex-row items-center gap-2"
                    onClick={() => {
                      mutationClaim.mutate({id: payment.id});
                      setSelectPaymentId(payment.id.toString());
                    }}>
                    <FiInbox className="text-[20px]"/>
                      Reclamer
                      {mutationClaim.isLoading && selectPaymentId === payment.id.toString() ? <LoadingIcon height={16} /> : null}
                    </button>
                  </div>
                )}
                {payment.status === "success" && (
                  <button 
                  onClick={() => handleDownloadInvoice(payment)}
                  className="border-[2px] border-[#0E9F90] px-4 py-1 rounded-md flex flex-row items-center justify-center gap-2">
                    <FiFileText className="text-[20px] text-[#0E9F90]"/>
                    Telecharger le recu
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      </div>
    </Layout>
  );
};

export default PaymentList;