import { Layout } from "../../../components/layout";
import SituationList from "./list";
import CreateSituationPage from "..";
import { useState } from "react";
import { Situation } from "../fetchdata";
import { useUserProfile } from "../../profile/hooks";

const SituationListPage: React.FC = () => {
  const {data: user} = useUserProfile();

  const [showCreateSituation, setShowCreateSituation] = useState<boolean>(false);
  const [selectedSituation, setSelectedSituation] = useState<Situation>();
  let closeSidePannel = () => {
    setShowCreateSituation(false);
    setSelectedSituation(undefined);
  }
  let sidePannelView = null;
  let sidePannelStyle = ''
  let sidePannelTitle = ''

  if (showCreateSituation || selectedSituation) {
    sidePannelView = <CreateSituationPage result={selectedSituation?.situation} title={selectedSituation?.lessonTitle} />
    sidePannelStyle=''
    sidePannelTitle = selectedSituation ? 'Situation enregistrée' : 'Creer une nouvelle situation'
  }

  return (
    <Layout activeMenu='Mes Situations' sidePannelView={sidePannelView} sidePannelOnclose={closeSidePannel} sidePannelStyle={sidePannelStyle} sidePannelTitle={sidePannelTitle}>
      <div className="flex flex-row items-center justify-between sm:p-3">
      <h2 className="text-primary50/80 text-lg ">Mes situations</h2>
      {(user?.availableStandaloneMonthlySubscription || 0) > 0 &&<button className='h-[40px] w-[250px] bg-primary50/80 rounded-[10px] text-secondary50' onClick={() => {
        setShowCreateSituation(true);
      }}>Creer une nouvelle situation</button>}  
      </div>

       <SituationList setSelectedSituation={setSelectedSituation} />
    </Layout>
  );
};

export default SituationListPage;
