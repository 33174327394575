import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import Input from '../../../components/input';
import Button from '../../../components/button';
import { useResetPassword } from './hooks';
import { useNavigate } from 'react-router-dom';
import makeToast, { defaultOptions } from '../../../utils/toast';

interface IFormInput {
    password: string;
    passwordConfirmation: string;
  }

const ResetPassword: React.FC = () => {
  const { register, handleSubmit } = useForm<IFormInput>();
  const history = useNavigate();
  const mutation = useResetPassword(history);
  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if(data.password !== data.passwordConfirmation) {
        makeToast('Les mots de passe ne correspondent pas', {
          ...defaultOptions,
          type: 'error',
        });
        return;
    }
    mutation.mutate(data);
  };

//   const errorMessage = mutation.error?.isAxiosError
//     ? mutation?.error?.response?.data?.message
//     : 'An error happened';

  return (
    <div className="flex flex-row h-screen bg-[#FFF7F1] items-center justify-center">
        {mutation.error ? (
          <div className="bg-[#FB1278] text-white text-sm text-center py-2 rounded-[5px] my-2">
            {/* {errorMessage} */}
          </div>
        ) : null}
      <div className="rounded-[15px] p-8 md:w-[40%] lg:w-[35%] sm:w-[90%] self-center bg-[#FFE8DE]">
          <form onSubmit={handleSubmit(onSubmit)}>
          <p className="mb-12 mt-6 mx-auto text-center text-[20px]">
               Nouveau mot de passe
            </p>
            <Input
              type="password"
              name="password"
              register={register}
              placeholder="Nouveau mot de passe"
              inputStyle="bg-[#FFD2BF] border-[0px] text-[#000] text-center text-[16px] mb-4"
            />
            <Input
              type="password"
              name="passwordConfirmation"
              register={register}
              placeholder="Retaper le nouveau mot de passe"
              inputStyle="bg-[#FFD2BF] border-[0px] text-[#000] text-center text-[16px]"
            />
            <Button
              isLoading={mutation.isLoading}
              label={mutation.isLoading ? 'Envoie' : 'Envoyer'}
              height="h-[36px]"
              textColor="white"
              width="w-full rounded-lg"
              isSubmit
            />
          </form>
      </div>
    </div>
  );
};

export default ResetPassword;