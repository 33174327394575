import { FC } from "react"
import { Fiche, useFicheMutation } from "./hook"
import { useNavigate } from "react-router-dom";
import removeMd from 'remove-markdown';
import { LoadingIcon } from "../../../components/loader";

type DetailFicheProps = {
    onClose: () => void;
    fiche: Fiche;
    end?: boolean;
}

export const DetailFicheSecondaire: FC<DetailFicheProps> = ({fiche, onClose, end}) => {
    Object.keys(fiche).forEach((key) => {
        const theKey = key as keyof Fiche;
        if (typeof fiche[theKey] === 'string') {
            // @ts-ignore
            fiche[theKey] = removeMd(fiche[theKey])?.replaceAll?.('\\', '');
        }
    });
    const navigation = useNavigate();
    const mutationDocx = useFicheMutation();
    const generateDocument = () => {
        mutationDocx.mutate(fiche.id);
    };
      
    return (
        <div className="bg-[#000]/20 fixed w-full h-full top-0 left-0 p-5 md:p-10 z-[90000] overflow-y-scroll">
            <div className="bottom-5 fixed items-center justify-center gap-4 flex flex-row w-[100vw]">
                {!end && <button onClick={() => onClose?.()} className="bg-[#ff0000] rounded-[5px] px-4 py-1 text-[#fff]">Fermer l'apercu</button>}
                {end && (
                    <>
                    <button onClick={() => navigation('/fiches')} className="bg-[#ff0000] rounded-[5px] px-4 py-1 text-[#fff]">Fermer</button>
                <button onClick={generateDocument} className="bg-[#0E9F90] rounded-[5px] px-4 py-1 text-[#fff] ml-5 shadow-lg shadow-[#0E9F90]">Enregistrer {mutationDocx.isLoading && <LoadingIcon height={15}  />}</button>
                </>
                )}
            </div>
            <div className="bg-[#fff] w-full md:w-3/4 p-5 mx-auto">
                <div className="border-b-[1px] border-b-primary50 pb-5 whitespace-pre-wrap">
                        <h1 className="text-[16px] font-bold mb-2">COMPETENCES</h1>
                        Après avoir réalisé l'ensemble des activités proposées, l'élève sera capable de traiter avec succès et de manière acceptable des situations faisant appel à des savoirs essentiels de la catégorie <b>" {fiche.lessonTitle} "</b>.
                </div>
                <div className="border-b-[1px] border-b-primary50 pb-5 whitespace-pre-wrap">
                    <h1 className="text-[16px] font-bold mb-2">SITUATION</h1>
                    {fiche.situation}
                </div>
                <div className="gap-5 flex flex-col p-2 md:p-5 border-[#000] border-[1px]">
                    <h1 className="text-[16px] font-bold mb-2">REVISION</h1>
                    <div className="border-b-[1px] border-b-primary50 pb-5 flex md:flex-row flex-col md:justify-between justify-normal">
                        <div className=" whitespace-pre-wrap w-full md:w-[49%]">
                            <h1 className="text-[16px] font-bold mb-2">Activités de l'enseignant</h1>
                            {fiche.revisionTeacher?.trimStart?.()}
                        </div>
                        <div className="md:w-[1px] w-full md:h-full  h-[1px] bg-primary50 my-5 md:my-0" />
                        <div className="whitespace-pre-wrap w-full md:w-[49%]">
                        <h1 className="text-[16px] font-bold mb-2">Activités de l'eleve</h1>
                            {fiche.revisionStudent?.trimStart?.()}
                        </div>
                    </div>
                    <h1 className="text-[16px] font-bold mb-2">ACTIVITES PRINCIPALES</h1>
                    <div className="border-b-[1px] border-b-primary50 pb-5 flex md:flex-row flex-col md:justify-between justify-normal">
                        <div className=" whitespace-pre-wrap w-full md:w-[49%]">
                            <h1 className="text-[16px] font-bold mb-2">Activités de l'enseignant</h1>
                            {fiche.activitePrincipaleTeacher}
                        </div>
                        <div className="md:w-[1px] w-full md:h-full  h-[1px] bg-primary50 my-5 md:my-0" />
                        <div className=" whitespace-pre-wrap w-full md:w-[49%]">
                            <h1 className="text-[16px] font-bold mb-2">Activités de l eleve</h1>
                            {fiche.activitePrincipaleStudent}
                        </div>
                    </div>
                    <h1 className="text-[16px] font-bold mb-2">SYNTHESE</h1>
                    <div className="border-b-[1px] border-b-primary50 pb-5 flex md:flex-row flex-col md:justify-between justify-normal">
                        <div className=" whitespace-pre-wrap w-full md:w-[49%]">
                            <h1 className="text-[16px] font-bold mb-2">Activités de l'enseignant</h1>
                            {fiche.syntheseTeacher}
                        </div>
                        <div className="md:w-[1px] w-full md:h-full  h-[1px] bg-primary50 my-5 md:my-0" />
                        <div className=" whitespace-pre-wrap md:w-[49%] w-full">
                            <h1 className="text-[16px] font-bold mb-2">Activités de l eleve</h1>
                            {fiche.syntheseStudent}
                        </div>
                    </div>
                    <h1 className="text-[16px] font-bold mb-2">EXERCICES</h1>
                    <div className="border-b-[1px] border-b-primary50 pb-5 flex flex-row justify-between whitespace-pre-wrap">
                        {fiche.exercice}
                    </div>
                    <h1 className="text-[16px] font-bold mb-2">SITUATIONS SIMILAIRES </h1>
                    <div className="border-b-[1px] border-b-primary50 pb-5 flex flex-row justify-between whitespace-pre-wrap">
                        {fiche.situationSimilaire}
                    </div>
                </div>
            </div>
        </div>
    )
}