import React, { useState } from "react";
import { Formik } from "formik";
import InputDropdown from "../../components/inputDropdown";
import Button from "../../components/button";
import { useUserProfile } from "../profile/hooks";
import { useClassForm } from "./mutation";
import { schoolOptions } from "../../utils/schools";

const ClassForm: React.FC = () => {
  const [selectedLevel, setSelectedLevel] = useState<string>("");
  const [resultData, setResultData] = useState<any>(null);
  const { data: user } = useUserProfile();

  const mutation = useClassForm((data: any) => {
    setResultData(data);
    setSelectedLevel("");
  });

  const getClasses = () => {
    const classes = [];
    const primaire = ['1', '2', '3', '4', '5', '6'];
    const ctb = ['7', '8'];
    const secondaire = ['1', '2', '3', '4'];
    const maternel = ['1', '2', '3'];

    const level =
      selectedLevel === 'primaire'
        ? primaire
        : selectedLevel === 'ctb'
        ? ctb
        : selectedLevel === 'maternelle'
        ? maternel
        : secondaire;

    const adding = selectedLevel === 'secondaire' || selectedLevel === 'maternelle';
    const suffix = selectedLevel === 'maternelle' ? 4 : 0;

    for (let i = 0; i < level.length; i++) {
      classes.push({
        label: `${level[i]} ${selectedLevel}`,
        value: `${adding ? Number.parseInt(level[i]) + 9 + suffix : Number.parseInt(level[i])}`,
      });
    }
    return classes;
  };

  return (
    <div className="md:w-[500px] sm:w-full sm:min-w-[300px] overflow-y-scroll no-scrollbar">
      <Formik
        initialValues={{
          classe: "",
          level: "",
          option: "",
          section: "",
          schoolYear: "",
          teacherId: user?.id || "",
          schoolId: "",
        }}
        onSubmit={(values) => {
          const formData = {
            ...values,
            teacherId: user?.id || "", // Assign teacherId from logged in user
          };
          mutation.mutate(formData);
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <div className="flex flex-col gap-5 overflow-y-scroll no-scrollbar">
            {!mutation.isLoading && resultData?.status === "success" && (
              <div className="bg-[#CAFFD9] rounded-[10px] p-3 flex flex-row justify-center items-center">
                <p className="text-md text-center">
                  Votre nouvelle classe a été créée avec succès
                </p>
              </div>
            )}
            {!(!mutation.isLoading && resultData?.status === "success") && (
              <div className="h-screen flex flex-col gap-4">
                <InputDropdown
                  label="Ecole"
                  placeholder="Sélectionner"
                  items={schoolOptions}
                  onSelect={(item: string) => {
                    setFieldValue("schoolId", item); // Assign schoolId
                  }}
                />

                <InputDropdown
                  label="Niveau"
                  placeholder="Sélectionner un niveau"
                  items={[
                    { value: "maternelle", label: "Maternelle" },
                    { value: "primaire", label: "Primaire" },
                    { value: "ctb", label: "CTB" },
                    { value: "secondaire", label: "Secondaire" },
                  ]}
                  onSelect={(item: string) => {
                    setFieldValue("level", item);
                    setSelectedLevel(item);
                    setFieldValue("class", ""); // Reset class when level changes
                  }}
                />

                <InputDropdown
                  label="Classe"
                  placeholder="Sélectionner une classe"
                  items={getClasses()}
                  onSelect={(item: string) => {
                    setFieldValue("classe", item); // Assign classe
                  }}
                />

                {/* Conditionally render Option and Section fields when "secondaire" is selected */}
                {selectedLevel === "secondaire" && (
                  <>
                    <InputDropdown
                      label="Option"
                      placeholder="Sélectionner une option"
                      items={[
                        { value: "option1", label: "Option 1" },
                        { value: "option2", label: "Option 2" },
                      ]}
                      onSelect={(item: string) => {
                        setFieldValue("option", item); // Assign option
                      }}
                    />

                    <InputDropdown
                      label="Section"
                      placeholder="Sélectionner une section"
                      items={[
                        { value: "section1", label: "Section 1" },
                        { value: "section2", label: "Section 2" },
                      ]}
                      onSelect={(item: string) => {
                        setFieldValue("section", item); // Assign section
                      }}
                    />
                  </>
                )}

                <div className="w-[140px]">
                  <Button
                    label="Ajouter"
                    height="h-[35px] rounded-[5px] self-start w-[140px] m-0"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </Formik>
    </div>
  );
};

export default ClassForm;