import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type Payment = {
  id: number;
  amount: string;
  status: string;
  metadata: any;
  createdAt: string;
};

export const usePaymentList = (tokens: string) => {
  return useQuery(["payments"], async (): Promise<Payment[]> => {
    const token = localStorage.getItem("token");
    const result = await axios.get("https://bot.harvely.com/api/payments", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data || [];
  });
};


export const useUserList = (search: string) => {
  return useQuery(["users", {search}], async (): Promise<any[]> => {
    const token = localStorage.getItem("token");
    const result = await axios.get(`https://bot.harvely.com/api/users?search=${search}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data || [];
  });
};