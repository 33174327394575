import { FC, ReactNode, useState } from "react";
import Sidebar, { MobileSidebar } from "./sidebar";
import { SidePannel } from "./sidePannel";

interface LayoutProps {
  children: ReactNode;
  activeMenu: string;
  sidePannelView?: any;
  sidePannelStyle?: string;
  sidePannelTitle?: string;
  sidePannelOnclose?: () => void;
}

export const Layout: FC<LayoutProps> = (props) => {
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  return (
    <div className="flex md:flex-row sm:flex-col w-full bg-[#fff]">
      <Sidebar
        mobileOpenned={mobileOpen}
        toogleMobileSidebar={() => {
          setMobileOpen(!mobileOpen);
        }}
        activeMenu={props.activeMenu}
      />
      <MobileSidebar
        toogleMobileSidebar={() => {
          setMobileOpen(!mobileOpen);
        }}
      />
      <div
        className="flex-grow md:p-10 md:ml-[250px] no-scrollbar sm:mt-[70px] sm:w-[100vw]"
        onClick={() => {
          setMobileOpen(false);
        }}
      >
        {props.children}
      </div>
      {props.sidePannelView && (
        <SidePannel
          sidePannelOnclose={props.sidePannelOnclose}
          style={props.sidePannelStyle}
          sidePannelTitle={props.sidePannelTitle}
        >
          {" "}
          {props.sidePannelView}{" "}
        </SidePannel>
      )}
    </div>
  );
};
