import React, { useEffect, useState } from 'react';
import { Layout } from '../../../components/layout';
import { useFicheQuery, useGptFicheForm } from './hook';
import { useLocation } from 'react-router-dom';
import { DetailFicheSecondaire } from './detail';
import { LoadingIcon } from '../../../components/loader';
import { FicheStep, getSteps } from '../../../utils/fiche';
import { DetailFichePrimaire } from './detailPrimaire';
import { DetailFicheMaternelle } from './detailMaternelle';
import { ImageViewer } from './image-viewer';
import { extractSearchTerm, regexContent, regexImage, regexMaterial } from '../../../utils/image-processing';
import removemd from 'remove-markdown';

export const CreateFiche = () => {
    const id = useLocation().pathname.substring(useLocation().pathname.lastIndexOf('/') + 1).split('?')[0];

    const { data, isLoading } = useFicheQuery(id);
    const mutation = useGptFicheForm(() =>{});
    const [showDetail, setShowDetail] = useState(false);
    const ficheSteps = getSteps(data);
    const value = data?.[ficheSteps[data?.currentStep - 1]?.slug];

    const isIllustrationImage = [FicheStep.RevisionTeacherMotivation, FicheStep.Situation].includes(ficheSteps[(data?.currentStep || 1) - 1]?.slug);
    const [text, setText] = useState<string | undefined>(undefined);
    const DetailFiche = Number.parseInt((data?.classe || '1'), 10) < 7 ? DetailFichePrimaire : Number.parseInt((data?.classe || '1'), 10) < 13  ?  DetailFicheSecondaire : DetailFicheMaternelle;

    const valueDisplayed = removemd(value || '');

    useEffect(() => {
        if (data && 'error' in data && data?.error === 'token') {
            window.location.replace('/login');
        }
    }, [data]);
    
    return (
        <Layout activeMenu='Mes Fiches' sidePannelView={null}>
        {((data && showDetail) || data?.currentStep === ficheSteps.length) && <DetailFiche end={data?.currentStep === ficheSteps.length} fiche={data} onClose={() => setShowDetail(false)} />}
        
        <div className='h-[100vh] w-full flex flex-col p-5 md:p-0'>
            <div className='flex flex-row justify-between gap-3 items-center'>
                <h1 className="font-bold text-[15px] md:text-[18px]"> {data?.lessonTitle}</h1>
                <button onClick={() => setShowDetail(true)} className='bg-[#000] px-3 py-0 text-[#fff] text-[14px] h-[35px] rounded-md min-w-[150px]'>Voir l'apercu</button>
            </div>
            {(isLoading || mutation.isLoading) && (
                <div className='mt-8'>
                    <LoadingIcon />
                    <p className='mt-4'>Patientez un peu, nous enregistrons <b>[{ficheSteps[(data?.currentStep || 1) - 1]?.title}]</b> et preparons <b>[{ficheSteps[data?.currentStep || 1]?.title}]</b></p>
                </div>
            )}
            {!(isLoading || mutation.isLoading) && <>
                <div className='bottom-[0px] md:bottom-[30px] w-full md:w-fit left-0 md:left-auto fixed flex flex-row gap-10 rounded-[5px] bg-yellow px-5 py-2'>
                    <button type="submit" className="bg-[#000] rounded-[5px] text-[#fff] py-2 px-10 w-[150px]" onClick={() => {
                        mutation.mutate({id, retry: true})
                    }}> Reesayer</button>
                    <button type="submit" className="bg-[#0E9F90] text-[#fff] rounded-[5px] py-2 px-10 w-[150px]" onClick={() => {
                        mutation.mutate({id, data: `${value}` !== text ? `${value}` : undefined});
                    }}> Suivant</button>
                </div>
                <div className="mt-[40px] flex flex-row justify-between mb-[30px]">
                    <h1 className='font-bold'>Etape {data?.currentStep} / {ficheSteps.length} : [{ficheSteps[(data?.currentStep || 1) - 1]?.title}]</h1>
                </div>
                {isIllustrationImage && <ImageViewer aiImages={data?.aiImages} prompt={extractSearchTerm(value || '', regexImage, 2) || ''} ficheId={id} images={data?.images} aiImageApiLink={data?.aiImageApiLink} />}
                <textarea  value={`${valueDisplayed}`} className='w-full bg-[#0E9F90]/10 min-h-[300px] p-5 rounded-[8px] flex-grow mb-[100px]' />
            </>}
            <div />
        </div>
        </Layout>
    );
}