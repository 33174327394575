import React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import ReactPaginate from 'react-paginate';

type PropTypes = {
  pagesCount: number;
  currentPage: number;
  onPageChange: any;
};

const Pagination: React.FC<PropTypes> = (props) => {
  const { pagesCount, onPageChange } = props;
  const pages = [];
  for (let i = 1; i <= pagesCount; i++) {
    pages.push(i);
  }

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={
        <div className="border-2 p-1 border-primary50 rounded-[5px]">
          <ChevronRight className="text-primary50" />
        </div>
      }
      onPageChange={onPageChange}
      pageRangeDisplayed={1}
      pageCount={pagesCount}
      previousLabel={
        <div className="border-2 p-1 border-primary50 rounded-[5px]">
          <ChevronLeft className="text-primary50" />
        </div>
      }
      containerClassName="flex flex-row my-4 md:my-10 gap-5 align-center items-center justify-center"
      activeClassName="bg-primary50 text-[#ffffff]"
      pageClassName="md:min-w-[30px] sm:min-w-[20px] text-primary50 text-center px-2 py-1 border-primary50 rounded-[5px]"
    />
  );
};

export default Pagination;