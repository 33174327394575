import { useMutation } from '@tanstack/react-query';
import { postApi } from '../../../../utils/api';
import { AxiosError } from 'axios';
import makeToast, { defaultOptions } from '../../../../utils/toast';

export const useForget = (navigate: any) => {
  const forget = (data: any) => {
    return postApi('v1/auth/forgot/password', data);
  };
  return useMutation(forget, {
    onSuccess: () => {
      makeToast('Le lien de réinitialisation a ete envoye à votre addresse email', defaultOptions);
      navigate('/login');
    },
    onError: (error: AxiosError) => {
      // //console.log(error);
      makeToast('Une erreur est survenue, veuillez réessayer.', {
        type: 'error',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    },
  });
};