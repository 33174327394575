import { useQuery, useQueryClient } from '@tanstack/react-query';
import { User } from '../../../features/users/types/user';
import { api } from '../../../utils/api';
import makeToast, { defaultOptions } from '../../../utils/toast';
import { logout } from '../../../utils/auth';

export const useUserProfile = (token = '', validateToken = false) => {
  const client = useQueryClient()
  return useQuery(
    ['user-profile'],
    async (): Promise<User | null> => {
      if(!token && validateToken) throw new Error('No User');
      const data = await api.v1('auth/me');
      if (data && data.email) {
        return data;
      }
      throw new Error('User not found');
    },
    {
      retry: 0,
      networkMode: 'online',
      useErrorBoundary: false,
      // staleTime: 10 * (60 * 1000), // 10 mins 
      // cacheTime: 15 * (60 * 1000), // 15 mins 
      onError: (error: any) => {
        if (error?.status === 401) makeToast('Session Expirée', defaultOptions);
        logout(client);
      },
    },
  );
};