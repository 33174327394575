import React, { useState } from "react";
import Button from "../../components/button";
import Input from "../../components/input";
import { Formik } from "formik";
import InputDropdown from "../../components/inputDropdown";
import { BsFillExclamationCircleFill } from "react-icons/bs";

type Props = {
  name: string;
  label: string;
  type: string;
  setValue: any;
  alternativeLabel?: string;
  options?: any[];
  show: boolean;
  result?: string;
  retry?: () => void;
  inputTextName?: string;
  titleOfTheLesson?: string;
  data?: any;
  creatable?: boolean;
  setIsFiche?: any;
  isFiche?: boolean;
  optionnal?: boolean;
  onSearch?: any;
  noticeText?: string;
  noticeAction?: () => void;
  isLoading?: boolean;
  selectLabel?: string;
  description?: string;
  noticeLink?: string;
  noticeTextAction?: string;
  placeholder?: string;
};

const SelectPage = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showInputText, setShowInputText] = React.useState(false);


  if (isLoading) {
    return (
      <div
        style={{
          height: "100%",
          zIndex: 90,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p style={{ textAlign: "center", fontSize: 35 }}>Enregistrement...</p>
      </div>
    );
  }
  const dataValue = props?.data?.[props.name] || props?.data?.["lessonTitle"];
  const alternative = dataValue
    ? props.options?.find((item) => item.value === dataValue)?.value || "other"
    : "";
  return (
    <div className="flex flex-col h-screen">
      {!props.result && (
        <>
          <h2 className=" text-2xl font-regular mb-10">{props.label}</h2>
          {props.description && <p className="text-primary-500 text-[15px] font-medium pb-4">
            <BsFillExclamationCircleFill className="text-primary-500 text-[20px] inline-block mr-2" />
            {props.description}
          </p>}
          <Formik
            initialValues={{
              class: "",
              subject: "",
              lessonTitle: "",
              areaOfLife: "",
              manualLesson: "",
              lessonId: "",
            }}
            onSubmit={(values) => {
              const name = props.name as keyof typeof values;
              props.setValue(values[name] || props?.data?.[name], name, undefined, false, props.optionnal);
              // const inputTextName = props.inputTextName || 'lessonTitle';
              // const selected = values[name] || props?.data?.[name];
              // // @ts-ignore
              // if (values[inputTextName] || (props?.data?.[inputTextName] && selected === 'other')) {
              //   props.setValue(
              //     // @ts-ignore
              //     values[inputTextName] || props?.data?.[inputTextName],
              //     inputTextName.replace('Alternative', ''),
              //     true,
              //   );
              // }
            }}
          >
            {({ handleChange, handleSubmit, setFieldValue }) => (
              <div className="flex flex-col">
                {props.type === "select" && (
                  <InputDropdown
                    label={
                      props.selectLabel ? props.selectLabel : props.creatable
                        ? "Ecrivez puis selectionnez"
                        : "Selectionner"
                    }
                    placeholder=""
                    selectedItem={alternative}
                    items={props.options || []}
                    onSelect={function (item: string): void {
                      if (item === "other") {
                        setShowInputText(true);
                        return;
                      }
                      setFieldValue(props.name, item);
                    }}
                    creatable={props.creatable}
                    onSearch={props.onSearch}
                    isLoading={props.isLoading}
                  />
                )}

                {props.type === "textarea" && (
                  <textarea
                    placeholder={props.placeholder || props.label}
                    rows={3}
                    className="w-full border-[1px] border-[#000000] mt-4 rounded-[15px] p-3"
                    onChange={(e) => {
                      setFieldValue(props.name, e.target.value);
                    }}

                    defaultValue={props.data?.[props.name]}
                  />
                )}

                {props.type === 'text' && <>
                    <Input
                        onChange={(e: string) => {
                            setFieldValue(props.name, e);
                        }}
                        icon=""
                        label={props.alternativeLabel} 
                        placeholder={''} 
                        name={props.name}
                        inputStyle='rounded-[5px]'  
                        defaultValue={props.data?.[props.name]}             
                    />
                  </>
                }

                {(props.noticeText && props.noticeLink) && <div className="mt-4">
                  <a
                    className="text-primary-400 text-[15px] font-medium underline bg-none border-none"
                    href={props.noticeLink}
                    type="button"
                  >
                    {props.noticeText}
                  </a>
                </div>}

                {
                  (props.noticeTextAction && props.noticeAction) && <div className="mt-4">
                    <button
                      className="text-primary-400 text-[15px] font-medium border-[1px] bg-none border-[#000] rounded-[5px] p-2 border-solid"
                      onClick={props.noticeAction}
                      type="button"
                    >
                      {props.noticeTextAction}
                    </button>
                  </div>
                }

                <div className="flex-row p-0 m-0 pl-0 pr-0 text-left  mt-10">
                  <Button
                    label={"Precedent"}
                    height="h-[44px] inline-block rounded-[5px] self-start w-[150px] m-0 bg-yellow text-[#000] border-[1px] border-primary50 mt-0 lg:mt-0 md:mt-0"
                    textColor="#000"
                    onClick={() => {
                      setShowInputText(false);
                      props.setValue(
                        // @ts-ignore
                        "",
                        "",
                        false,
                        true
                      );
                    }}
                    centerStyle="mr-5"
                  />
                  
                  {(props.name !== 'areaOfLife' || (props.name === 'areaOfLife' && !props.isFiche)) && <Button
                    label={props.name === 'areaOfLife' ? 'Creer situation' : "Suivant"}
                    height="h-[44px] inline-block rounded-[5px] self-start w-[130px] m-0 mt-0 lg:mt-0 md:mt-0"
                    textColor="white"
                    onClick={(e: any) => {
                      props.setIsFiche(props.isFiche);
                      handleSubmit(e);
                    }}
                    centerStyle="text-secondary50"
                  />}
                  {(props.name === 'areaOfLife' && props.isFiche) && (
                                      <Button
                                      label={"Suivant"}
                                      height="h-[44px] bg-[#0E9F90] inline-block rounded-[5px] self-start w-[120px] ml-[20px] m-0 mt-0 lg:mt-0 md:mt-0"
                                      textColor="white"
                                      width="bg-[#0E9F90]"
                                      onClick={(e: any) => {
                                        props.setIsFiche(true);
                                        handleSubmit(e);
                                      }}
                                      centerStyle="text-secondary50"
                                    />
                  )}
                </div>
              </div>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};

export default SelectPage;
