import { useState } from "react";
import { Layout } from "../../components/layout";
import ClassForm from "./form";
import { FaRegPlusSquare } from "react-icons/fa";
import { previsions } from "../../utils/prevision";
import PrevisionForm from "./prevision";

const Classes: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'previsions' | 'fiches'>('previsions');
  const [showPrevisionForm, setShowPrevisionForm] =
    useState<boolean>(false);

  let sidePannelView = null;
  let sidePannelStyle = "";
  let sidePannelTitle = "";
  let closeSidePannel = () => {
    setShowPrevisionForm(false);
  };
  if (showPrevisionForm) {
    sidePannelView = <PrevisionForm/>;
    sidePannelStyle = "";
    sidePannelTitle = "Ajouter une nouvelle prevision";
  }

  return (
    <Layout
      activeMenu="Acceuil"
      sidePannelView={sidePannelView}
      sidePannelOnclose={closeSidePannel}
      sidePannelStyle={sidePannelStyle}
      sidePannelTitle={sidePannelTitle}
    >
      <div className="h-[100%]">
        <div className="px-5 mt-5 flex flex-row items-center gap-8">
          <button
            onClick={() => setActiveTab('previsions')}
            className={`px-6 py-2 ${activeTab === 'previsions' ? 'bg-[#0E9F90] text-[#fff]' : 'bg-[#0E9F90]/10 font-semibold'} rounded`}
          >
            Previsions
          </button>
          <button
            onClick={() => setActiveTab('fiches')}
            className={`px-6 py-2 ${activeTab === 'fiches' ? 'bg-[#0E9F90] text-[#fff]' : 'bg-[#0E9F90]/10 font-semibold'} rounded`}
          >
            Fiches
          </button>
        </div>

        <div className="px-5 mt-10">
          {activeTab === 'previsions' && (
            <div className="bg-[#0E9F90]/10">
              {previsions.length === 0 ? (
              <div className="flex flex-col items-center justify-center w-full h-[400px]">
                <p className="mb-8 text-[24px] font-semibold">Aucune prevision pour le moment</p>
                <button
                  className="bg-[#0E9F90] text-[#fff] flex flex-row items-center gap-4 py-2 px-8 rounded-[5px]"
                  onClick={() => setShowPrevisionForm(true)}
                >
                  <p>Ajouter une prevision</p>
                  <FaRegPlusSquare />
                </button>
              </div>
            ) : (
              <div>
                {previsions.map((p, index) => (
                  <div key={index}>
                    {/* <p>{p.name}</p>
                    <p>{p.domain}</p>
                    <p>{p.chapitre}</p>
                    <p>{p.course}</p> */}
                  </div>
                ))}
              </div>
            )}
            </div>
          )}

          {activeTab === 'fiches' && (
            <div>
              <p className="text-center text-primary-900 font-semibold text-[16px]">Schools Content</p>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Classes;