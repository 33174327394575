export const logout = (client?: any) => {
    const token = localStorage.getItem('token');
    if (token) {
      client?.removeQueries?.();
      localStorage.setItem('token', '');
      localStorage.removeItem('REACT_QUERY_OFFLINE_CACHE');
      window.location.assign('/');
    }
  };
  
  export const isAuth = () => {
    return localStorage.getItem('token');
  };