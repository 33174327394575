import { Route, Routes, Navigate } from "react-router-dom";
import Home from "../pages/home";
import SituationListPage from "../pages/situations/list";
import PaymentList from "../pages/payment/list";
import SituationDetailPage from "../pages/situations/detail";
import { CreateFiche } from "../pages/situations/full/create-fiche";
import { ListFiche } from "../pages/situations/full/list";
import Ressources from "../pages/ressources";
import Profile from "../pages/profile";
import Prevision from "../pages/classes";
import Classes from "../pages/classes/classe";
import TeachersList from "../pages/admin/list";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/situations" element={<SituationListPage />} />
      <Route path="/situations/detail" element={<SituationDetailPage />} />
      <Route path="/fiches/:id" element={<CreateFiche />} />
      <Route path="/previsions" element={<Prevision />} />
      <Route path="/teachers" element={<TeachersList />} />
      <Route path="/classes" element={<Classes />} />
      <Route path="/ressources" element={<Ressources />} />
      <Route path="/fiches" element={<ListFiche />} />
      <Route path="/payments" element={<PaymentList />} />
      <Route path="/profile" element={<Profile />} />
      <Route element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;
