import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: { borderRadius: 10,  padding: 30, },
  header: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    marginBottom: 12,
  },
  section: {
    marginBottom: 8,
    display: "flex",
    flexDirection: "column",
    gap: 20,
    borderRadius: 10,
  },
  title: { fontSize: 16, marginBottom: 8, textAlign: "center", },
  text: { fontSize: 14, marginBottom: 16 },
  amount: { fontSize: 28, textAlign: "center" },
  data: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  footer: {
    marginTop: 8,
    fontSize: 10,
    textAlign: 'center',
    color: 'grey',
  },
  logo: {
    width: 40,
    height: 40,
  },
  companyInfo: {
    textAlign: "right",
    fontSize: 10,
    color: ""
  },
});

const Invoice = ({ payment }: { payment: any })  => {

  return (
    <Document>
      <Page size={{ width: 400, height: 300 }} style={styles.page}>
      <View style={styles.header}>
        <Image 
          style={styles.logo} 
          src="https://res.cloudinary.com/dzj9hndxv/image/upload/v1723645705/IMG-20240717-WA0009-removebg-preview_w2nunt.png" // Replace with your logo URL or local path
        />
        
        <View style={styles.companyInfo}>
          <Text>Harvely Company</Text>
          <Text>Email: harvelyc@gmail.com</Text>
          <Text>Address: 072,Blvd Julien Paluku Kahongya, Q/Murara, C/Karisimbi</Text>
        </View>
      </View>
        <View style={styles.section}>
          <Text style={styles.title}>Reçu de Paiement</Text>
          <Text style={styles.amount}>${payment.amount}</Text>
          <View style={styles.data}>
            <View>
              <Text style={styles.text}>Date de paiement :</Text>
              <Text style={styles.text}>Type :</Text>
            </View>

            <View>
              <Text style={styles.text}>
                {payment.createdAt
                  ? new Date(payment.createdAt).toLocaleDateString('fr-FR')
                  : 'N/A'}
              </Text>
              <Text style={styles.text}>{payment?.metadata?.paymentType === "SUBSCRIPTION" ? "Abonnement" : "Fiches non expirables"}</Text>
            </View>
          </View>
        </View>
        <View style={styles.footer}>
        <Text>Harvely vous remercie pour votre confiance!</Text>
      </View>
      </Page>
    </Document>
  );
};

export default Invoice;