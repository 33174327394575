export const teachers = [
    { 
        firstName: "Kashigane",
        lastName: "Ntaboba Ethienne",
        telephone: "0991746590",
    },
    { 
        firstName: "Baguma",
        lastName: "Metre Yves",
        telephone: "0991746590",
    },
    { 
        firstName: "Mugisho",
        lastName: "Bashige Yves",
        telephone: "0991746590",
    },
  ];